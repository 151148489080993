import React, {useState, useEffect} from "react";
import { Box, Divider, VStack, Text,Heading, Container } from "@chakra-ui/react";
import SearchHeader from "./SearchHeader";
import Breadcrumb from "../Breadcrumb";
import '../search/styles.css';
import Footer from "../Footer";
import SignUpProgress from "../user/SignUpProgress";

const SearchComponent = () => {

   const [userId, setUserId] = useState(null);

  useEffect(() => {
        // Check if user ID exists in local storage
        const storedUserId = localStorage.getItem('userid');
        if (storedUserId) {
          setUserId(storedUserId);
        }
      }, []);

  return (
    <div className="bg-primary font-linksans pb-12">
      <div className='container'>
       <Box >
              <Box p={2}>
                     <Breadcrumb/>
                     {userId &&
                     <SignUpProgress/>
                     }
                     <Divider/>
                     <SearchHeader/>
              </Box>
       </Box>
       </div>
    </div>
   
  );
};

export default SearchComponent;
