import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

const CheckBoxRefinement = ({ items, currentRefinement, refine }) => {
  
  const handleCheckboxChange = (e) => {
    const value = e.target.checked ? 'true' : 'false';
    refine([value]); 
  };
  return (
    <div>
      <input type="checkbox" checked={currentRefinement.includes('true')} onChange={handleCheckboxChange} />
      <label> Only  Group Properties</label>
    </div>
  );
};

const ConnectedCheckBoxRefinement = connectRefinementList(CheckBoxRefinement);

export default ConnectedCheckBoxRefinement;
