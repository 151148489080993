import React,  { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { userProgressLinks } from "../../constants";

const SignUpProgress = () => {
  const location = useLocation();
  const role = localStorage.getItem("roles") || "buyer";
  //const user_flags = JSON.parse(localStorage.getItem("user_flags")) || { firstUpdate: 1 };
  //const user_flag = user_flags?.firstUpdate ?? 1;

  const [userFlag, setUserFlag] = useState(() => {
    // Get initial value from localStorage
    const user_flags = JSON.parse(localStorage.getItem("user_flags")) || { firstUpdate: 1 };
    return user_flags?.firstUpdate ?? 1;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedFlags = JSON.parse(localStorage.getItem("user_flags")) || { firstUpdate: 1 };
      setUserFlag(updatedFlags?.firstUpdate ?? 1);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Hide progress bar if user_flag >= 5
  if (userFlag  >= 5) return null;

  const steps = userProgressLinks[role] || {};
  const stepsArray = Object.entries(steps).map(([key, value]) => ({
    step: parseInt(key),
    label: value.label,
    path: value.path,
  }));

  // Determine active step based on user_flag
  const activeStepIndex = stepsArray.findIndex((step) => step.step === userFlag);

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      {/* Progress Bar */}
      <div className="relative flex items-center justify-between">
        {stepsArray.map((step, index) => (
          <div key={index} className="relative flex flex-col items-center w-full">
            {/* Step Circle with Tooltip */}
            <div className="relative flex flex-col items-center group">
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full cursor-pointer peer ${
                  activeStepIndex >= index ? "bg-green-600" : "bg-gray-400"
                }`}
              >
                <span className="text-white text-sm z-50">{step.step}</span>
              </div>

              {/* Tooltip: Visible on hover (desktop) or tap (mobile) */}
              <div className="min-w-[100px] absolute top-8 bg-black text-white text-xs px-2 py-1 rounded-md opacity-0 transition-opacity group-hover:opacity-100 peer-focus:opacity-100">
                {step.label}
              </div>
            </div>

            {/* Hide label on mobile, show on larger screens */}
            <span
              className={`mt-2 text-center text-sm hidden sm:block ${
                activeStepIndex >= index ? "text-green-600" : "text-gray-500"
              }`}
            >
              {step.label}
            </span>

            {/* Connecting Line */}
            {index < stepsArray.length - 1 && (
              <div
                className={`absolute top-[26%] left-full h-[2px] w-full ${
                  activeStepIndex >= index ? "bg-green-600" : "bg-gray-400"
                }`}
                style={{ transform: "translateX(-50%)" }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SignUpProgress;
