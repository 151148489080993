import './App.css';
import './assets/css/globals.css';
import Navbar from './components/NavBar';
import { ChakraProvider, CSSReset, Box} from '@chakra-ui/react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'; // Import useLocation
import customTheme from './components/customTheme';
import ProductComparison from './components/compare/ProductComparison';
import Footer from './components/Footer';
import SearchComponent from './components/search/SearchComponent';
import HomeComponent from './components/home/HomeComponent';
import ElasticSearchComponent from './components/elastic/SearchComponent';
import ListSearchComponent from './components/elastic/SearchComponent';
import MySearchApp from './components/elastic/MySearchApp';
import ScrollingTextWithArrow from './components/ScrollingTextWithArrow';
import ForgotPasswordForm from './components/user/forgotpassword';
import ResetPasswordForm from './components/user/resetpassword';
import UserProfileEdit from './components/user/profileEdit';
import SignupCard from './components/user/signup';
import Joingroup from './components/user/Joingroup';

import ProductsCompTable from './components/products/ProductsCompTable';

import ProductsProvider from './components/compare/ProductsProvider';
import TermsPage from './pages/static/TermsPage';
import PrivacyPolicyPage from './pages/static/PrivacyPolicyPage';
import CookieSettingsPage from './pages/static/CookieSettingsPage';
import { AuthProvider, AuthContext } from './components/user/AuthProvider';

// import Dashboard from './components/user/UserDashboard';
import Dashboard from './components/user/Dashboard';
import { PageViewsProvider } from './tracking/PageViewProvider';

//import ConfirmationPage from './components/user/ConfirmationPage';
import HowItWorks  from './components/user/HowItWorks';

import CommunityDetails from './components/CommunityDetails';
//import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import ContactPage from './pages/static/ContactPage';
import SupportPage from './pages/static/SupportPage';
import AboutUsPage from './pages/static/AboutUsPage';
import DisclosuresPage from './pages/static/DisclosuresPage';
import ReferralAgreementPage from './pages/static/ReferralAgreementPage';
//import BuyerHomePage from './pages/static/BuyerHomePage';
import AgentHomePage from './pages/static/AgentHomePage';
import BuilderHomePage from './pages/static/BuilderHomePage';
import WhyChooseUs from './pages/static/WhyChooseUs';
import BuyRentalsInGroup from './pages/static/BuyRentalsInGroup';
import ReadyMadeGroups from './pages/static/ReadyMadeGroups';
import StillWhy from './pages/static/StillWhy';
import MyPreference from './components/grid/MyPreference';
import GroupBuyerPreference from './components/grid/GroupBuyerPreference';
import MainListing from './components/grid/MainListing';
import AgentLicenseInfo from './components/user/AgentLicenseInfo';
import BuilderInfo from './components/user/BuilderInfo';
import MyAssignments from './components/grid/MyAssignments';
import BuilderAgentManagement from './components/grid/BuilderAgentManagement';
import GroupManagement from './components/user/GroupManagement';
import UserManagement from './components/grid/UserManagement';
import TableComponent from './components/tenstack/UserManagement';
import CommunityTable from './components/tenstack/CommunityTable';
import AssignAgent from './components/grid/AssignAgent';
import DetailsMain from "./pages/DetailsPage/DetailsMain/DetailsMain";
import GroupBuyerMonthlyEvent from './pages/static/GroupBuyerMonthlyEvent';
import GroupBuyerUnSubscribe from './pages/static/GroupBuyerUnSubscribe';
import ErrorPage from './pages/ErrorPage';
import ReferralRespond from './pages/DetailsPage/ReferralRespond';
import TicketManagement from './components/forms/TicketManagement';
import GetStartedPage from './components/grid/GetStartedPage';
import OnlineLeadPage from './components/user/GroupLeadPage';
import CompanyManagement from './components/grid/CompanyManagement';
import BuilderInformationForm from './components/user/BuilderInformationForm';
import SuccessPage from './components/user/SuccessPage';
import SignupSuccessPage from './components/user/SignupSuccessPage';
import AgentInformationForm from './components/user/AgentInformationForm';
import ReferralAgreementBuilder from './pages/static/ReferralAgreementBuilder';

function App() {
  return (
    <AuthProvider>
      <ProductsProvider>
        <ChakraProvider theme={customTheme}>
          <CSSReset />
          <Box minHeight="100vh">
            <PageViewsProvider>
              {/* <PageTracker/> */}
              <Navbar />
              {/* <Center className='container2'> */}
              {/* </Center> */}
              <Routes>
                <Route path="/" element={<BuyRentalsInGroup />} />
                <Route path="/sign-up" element={<SignupCard />} />
                <Route path="/sign-in" element={<ProtectedContent />} />
                <Route path="/login" element={<ProtectedContent />} />
                <Route path="/buyer/home/" element={<HomeComponent />} />
                <Route path="/builder/home/" element={<BuilderHomePage />} />
                <Route path="/agent/home/" element={<AgentHomePage />} />
                
                <Route exact path="/shortlisted/" element={<ProductComparison />} />
                <Route exact path="/shortlisted/:itemIds" element={<ProductsCompTable />} />
                <Route path="/shortlisted/at-address" element={<ProductComparison />} />
                <Route path="/elasticsearch/" element={<SearchComponent />} />
                
                <Route path="/search/" element={<ElasticSearchComponent />} />
                <Route path="/at/" element={<ListSearchComponent />} />
                <Route path="/mysearchapp/" element={<MySearchApp />} />
                <Route path="/textscroll/" element={<ScrollingTextWithArrow />} />
                <Route path="/forgot/" element={<ForgotPasswordForm />} />
                <Route path="/reset-password/" element={<ResetPasswordForm />} />
                <Route path="/editprofile/" element={<UserProfileEdit />} />
                
                <Route path="/terms/" element={<TermsPage />} />
                <Route path="/privacy/" element={<PrivacyPolicyPage />} />
                <Route path="/contact-us/" element={<ContactPage />} />
                <Route path="/support/" element={<SupportPage />} />
                <Route path="/about-us/" element={<AboutUsPage />} />
                <Route path="/disclosures/" element={<DisclosuresPage />} />
                <Route path="/agent/referral-agreement/:token?" element={<ReferralAgreementPage />} />
                <Route path="/builder/referral-agreement/:token?" element={<ReferralAgreementBuilder />} />
                <Route path="/cookie-policy/" element={<CookieSettingsPage />} />

                {/* get started forms pages */}
                <Route path="/success/" element={<SuccessPage />} />
                <Route path="/signup-success/" element={<SignupSuccessPage />} />
                <Route path="/agent/sign-up/" element={<SignupCard />} />
                <Route path="/builder/sign-up/" element={<SignupCard />} />
                <Route path="/builder/info/:userId/:kcUid" element={<BuilderInformationForm />} />
                <Route path="/agent/info/:userId/:kcUid" element={<AgentInformationForm />} />
                <Route path="/groupbuyers/co-buying-club/" element={<GroupBuyerMonthlyEvent />} />
                <Route path="/groupbuyers/company/:id?" element={<OnlineLeadPage />} />

                <Route path="/unsubscribe/" element={<GroupBuyerUnSubscribe />} />

                <Route path="/how-it-works/" element={<HowItWorks/>} />

                 <Route path="/home/:lang/:state/:city/:id/*" 
                  element={<DetailsMain />} />

                 <Route path="/home/:lang/:id/*" 
                  element={<DetailsMain />} />

                 {/* <Route path="/home/:lang/:state/:city/:id/*" element={<TabsComponent />} */}

                 <Route path="/community/:lang/:state/:city/:id/*" element={<CommunityDetails />} />
                 <Route path="/community/:lang/:id/*" element={<CommunityDetails />} />

                <Route path="/dashboard/*" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route  path="/dashboard/"
                  element={<PrivateRoute> <Dashboard /></PrivateRoute>}/>
                  
                {/* Buyer Routes  */}
                <Route path="/buyer/profile/" 
                  element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} />
               
               <Route path="/groups/" 
                  element={<PrivateRoute><GroupManagement /></PrivateRoute>} />
               <Route path="/buyer/buyer-preferences/" 
                  element={<PrivateRoute><MyPreference /></PrivateRoute>} />
          
                {/* Buyer Agent Routes  */}
                <Route path="/buyeragent/profile/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/buyeragent/invites/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/buyeragent/buyer-preferences/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/buyeragent/my-assignments/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                  {/* Admin Routes  */}
                <Route path="/admin/profile/" element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} />
                <Route path="/admin/users/" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
                <Route path="/admin/support-tickets/" element={<PrivateRoute><TicketManagement /></PrivateRoute>} />
                <Route path="/usertenstacktable/" element={<TableComponent />} />
                <Route path="/CommunityTable/" element={<CommunityTable />} />
               
                <Route path="/admin/properties/" element={<PrivateRoute><MainListing /></PrivateRoute>} />
                <Route path="/admin/assign-agent/" element={<PrivateRoute><AssignAgent /></PrivateRoute>} />
                <Route path="/admin/vendors/" element={<PrivateRoute><BuilderAgentManagement /></PrivateRoute>} /> 

                 {/* Staff Routes  */}
                 <Route path="/staff/profile/" element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} />
                <Route path="/staff/users/" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
                <Route path="/staff/support-tickets/" element={<PrivateRoute><TicketManagement /></PrivateRoute>} />
                <Route path="/staff/properties/" element={<PrivateRoute><MainListing /></PrivateRoute>} />
                <Route path="/staff/assign-agent/" element={<PrivateRoute><AssignAgent /></PrivateRoute>} />
                <Route path="/staff/vendors/" element={<PrivateRoute><BuilderAgentManagement /></PrivateRoute>} /> 
                <Route path="/staff/companies/" element={<PrivateRoute><CompanyManagement /></PrivateRoute>} />

                {/* Seller Routes  */}
                <Route path="/seller/properties/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/seller/profile/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                {/* Seller Agents Routes  */}
                <Route path="/agent/properties/" element={<PrivateRoute><MainListing /></PrivateRoute>} />
                <Route path="/agent/profile/" element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} />
                <Route path="/agent/agent-preferences/" element={<PrivateRoute><MyPreference /></PrivateRoute>} />
                <Route path="/agent/referrals/" element={<PrivateRoute><MyAssignments /></PrivateRoute>} />
                <Route path="/agent/agent-licence-info/" element={<PrivateRoute><AgentLicenseInfo /></PrivateRoute>} />
                <Route path="/agent/why-choose-us/" element={<WhyChooseUs />} />

               {/* Builder Routes  */}
                 <Route path="/builder/profile/" element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} /> 
                 <Route path="/builder/builder-info/" element={<PrivateRoute><BuilderInfo /></PrivateRoute>} />
                 <Route path="/builder/builder-preferences/" element={<PrivateRoute><MyPreference /></PrivateRoute>} />
                 <Route path="/builder/properties/" element={<PrivateRoute><MainListing /></PrivateRoute>} /> 
                 <Route path="/builder/builder-team/" element={<PrivateRoute><BuilderAgentManagement /></PrivateRoute>} />
                 <Route path="/builder/referrals/" element={<PrivateRoute><MyAssignments /></PrivateRoute>} />
                 <Route path="/builder/why-choose-us/"  element={<WhyChooseUs />} />
                
               {/* Builder Teams Routes  */}
               <Route path="/builderteam/profile/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> 
                <Route path="/builderteam/properties/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> 
                <Route path="/builder/builderteams/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/builder/tour_list/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />

                <Route path="/builderagent/profile/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/builder/builder_info/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> 
                <Route path="/builderagent/properties/" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> 
                <Route path="/builderagent/agent_preferences/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/builderagent/my_assignments/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/groups/joingroup" element={<PrivateRoute><Joingroup /></PrivateRoute>} />

                <Route path="/referrals/act/:token" element={<PrivateRoute><ReferralRespond /></PrivateRoute>} />
                
                <Route path="/groupbuyers/profile/" element={<PrivateRoute><UserProfileEdit /></PrivateRoute>} />
                <Route path="/groupbuyers/preferences/" 
                  element={<PrivateRoute><GroupBuyerPreference /></PrivateRoute>} />
                <Route path="/groupbuyers/getstartpage/" 
                  element={<GetStartedPage />} />
                <Route path="/groupbuyers/home/" element={<BuyRentalsInGroup />} />
                {/* <Route path="/grouprentals/home/" element={<PrivateRoute><BuyRentalsInGroup /></PrivateRoute>} /> */}
                <Route path="/groupbuyers/list/" element={<ReadyMadeGroups />} />
                <Route path="/groupbuyers/why-choose-us/" element={<WhyChooseUs />} />
                <Route path="/groupbuyers/still-why/" element={<StillWhy/>} />
                <Route path="*" element={<ErrorPage />} />
                </Routes>
              <FooterControlled />
            </PageViewsProvider>
          </Box>
        </ChakraProvider>
      </ProductsProvider>
    </AuthProvider>


  );
}

const FooterControlled = () => {
  const location = useLocation();

  // Hide footer on the '/search' route path
  if (location.pathname === '/search') {
    return null;
  }

  return <Footer />;
};

const ProtectedContent = () => {

  return (
    <AuthContext.Consumer>
      {({ isAuthenticated, keycloak }) =>
        isAuthenticated ? <Navigate to="/search" /> : (
          <div className="min-h-svh" style={{textAlign:"center", padding:"5px"}}>loading...</div>
        )
      }
    </AuthContext.Consumer>
  );
};

export default App;
