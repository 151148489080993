import React from "react";
import { Flex, Select, Tabs, TabList, Tab, Input, IconButton, Box , Icon, InputLeftElement, InputGroup} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next'; 
import { SearchIcon } from "@chakra-ui/icons"; // You might need to install this icon component separately
import { useState } from 'react';
import ElasticSearchComponent from './GeomapSearchComponent';
import ListSearchComponent from './GeoMap';
const SearchHeader = () => {

    const handleSearch = (event) => {
        // Handle search functionality here
        console.log(event.target.value);
      };
      

      // State to manage the visibility of the search-map-sec section
  const [showMap, setShowMap] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  // Function to handle tab change
  const handleTabChange = (index) => {
    setSelectedTab(index);
    console.log(index);
    setShowMap(index === 0); // Set showMap to true if index is 1 (for Map tab), otherwise false
  };

  return (
    <>
    <Flex p="1" alignItems="center" justifyContent="space-between" pb="2">
      {/* Left side */}
      <Flex alignItems="center" flex={{ base: "none", md: 7}}>
        {/* Search Bar */}
        <Box flex={{ base: "none", md: 6 }}>
        <InputGroup>
          <Input className="search-input" placeholder="Search" onChange={handleSearch} />
          <InputLeftElement 
          className='search-btn' pointerEvents="none" children={<Icon as={SearchIcon} color="gray.300" />} />
          {/*<IconButton
            className="search-btn"
            aria-label="Search"
            icon={<SearchIcon />}
            variant="outline"
            size="sm"
            ml="-10"
            mt="1"
            zIndex="99"
          />
          */}
            </InputGroup>
          </Box>

        <Select placeholder="Sort By" ml="4" flex={{ base: "none", md: 2 }}>
          <option value="option1">Option 1</option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>

        {/* <Button colorScheme="blue" mr="2" minWidth="100px" BorderRadius="25px">Save Search</Button> */}
      </Flex>
      <Box flex={{ base: "none", md: 3}}>
      {/* Right side  */}
      <Tabs align="end" variant="enclosed" index={selectedTab} onChange={handleTabChange}>
        <TabList>
          <Tab>Map</Tab>
          <Tab>List</Tab>
        </TabList>
      </Tabs>
      </Box>
    </Flex>
    {showMap==true?<ListSearchComponent/>:<ListSearchComponent/>}
    </>
  );
};

export default SearchHeader;
