import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack,
  Text,
  IconButton,
  Box,
  Flex,
  Heading,
  FormErrorMessage,
  Collapse,
  Checkbox,
  Badge,
  Tooltip,
  useToast,
  Menu,
  MenuButton,
  MenuList, 
  MenuItem
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import {  FaExchangeAlt } from 'react-icons/fa';
import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import Swal from 'sweetalert2';
//import { useProducts } from '../compare/ProductsProvider';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 


function ShareWithFriendsModal({ isOpen, onClose, propertyId }) {

  const [groups, setGroups] = useState([]);
  const [groupsDataById, setGroupsDataById] = useState({});
  const [groupName, setGroupName] = useState('');
  const [groupNameValid, setGroupNameValid] = useState(true);
  const [isDuplicateGroupName, setIsDuplicateGroupName] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const [checkedGroups, setCheckedGroups] = useState({});
  const { t} = useTranslation();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const userId = localStorage.getItem('userid');
  const [groupCounts, setGroupCounts] = useState({});
   const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  //const { lovedProducts, addProductToLovedlist, removeProductFromLovedlist } = useProducts();

  useEffect(() => {
    fetchGroups();
    fetchGroupCounts();
  }, []);

  const fetchGroupCounts = async()=>{
    //usrgroup/all/count
    try {
      const response = await axiosInstance.get(`/usrgroup/all/count`);
      
      const responseData = response.data || [];
      setGroupCounts(responseData || {});
    } catch (error) {
      setError(error.message);
    }
  }
  const fetchGroups = async () => {
    // Fetch groups from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup`);
     // const responseData = await response.json();
      setGroups(response.data || []);
    } catch (error) {
      setError(error.message);
    }
  };


  const fetchGroupDataById = async (groupId) => {
    // Fetch group data by ID from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup/${groupId}/users`);
      //console.log("initteeusers",response.data);
      setGroupsDataById((prevData) => ({ ...prevData, [groupId]: {users:response.data || [] } }));
    } catch (error) {
      setError(error.message);
    }
  }
useEffect(()=>{
}, groupsDataById);

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    setGroupNameValid(true);
    setIsDuplicateGroupName(false);
  };

  
  const fetchSuggestions = async (term) => {
    try {
      const response = await axiosInstanceES.get(`/company-suggestions?term=${term}`);
      setSuggestions(response.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setGroupName(value);
      if (value.length > 2) {  
        fetchSuggestions(value);
      } else {
        setShowSuggestions(false);
      }
    };
  
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && groupName) {
        setShowSuggestions(false);  
      }
    };
  
    const handleSelectSuggestion = (suggestion) => {
      setGroupName(suggestion.company_name); 
      setSelectedCompanyId(suggestion.companyid); 
      setShowSuggestions(false);
    };

  const MAX_GROUPS = process.env.REACT_APP_MAX_GROUPS;
  const MAX_INVITES_PER_GROUP = process.env.REACT_APP_MAX_INVITES_PER_GROUP;

  const handleCreateGroup = async () => {
    let isValid = true;

    if (!groupName.trim() || groupName.trim().length > 50) {
      setGroupNameValid(false);
      isValid = false;
    }

    const groupExists = groups.some(group => group.name.toLowerCase() === groupName.trim().toLowerCase());
  if (groupExists) {
    setIsDuplicateGroupName(true);
    toast({
      title: 'Error',
      description: t('group-alreadyexistmessage'),
      status: 'error',
      duration: 5000,
      isClosable: true,
       position: 'top-right',
    });
    isValid = false;
  }

    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      if (groups.length >= MAX_GROUPS) {
        let errorMessage = t('group-limitmessage');
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
           position: 'top-right',
        });
        return;
      }

      const groupRequestData = selectedCompanyId
      ? {
          name: groupName,
          isPublic: true, 
          companyId: selectedCompanyId, 
        }
      : { name: groupName };

      const response = await axiosInstance.post(`/usrgroup`, groupRequestData);
      const data = response.data;
     
      setGroups([...groups, data]);
      setGroupName('');

      toast({
        title: t('group-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
         position: 'top-right',
      });

    } catch (error) {
      setError(error.message);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
         position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInviteUsers = async () => {
    try {
      let isFormValid = true;

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!userData.email.trim() || !emailRegex.test(userData.email.trim())) {
        setEmailValid(false);
        isFormValid = false;
      } else {
        setEmailValid(true);
      }


      if (!isFormValid) {
        return;
      }

      const inviteData = {
        invites: [userData.email], // Add more emails if needed
      };

      // const inviteData = {
      //   invites: [
      //     {
      //       first_name: userData.firstName,
      //       last_name: userData.lastName,
      //       email: userData.email,
      //       phone: userData.phone,
      //     }
      //   ]
      // };

      const response = await axiosInstance.post(`/usrgroup/${selectedGroup.id}/invite_batch`,
        inviteData);
      if (!response.data.insertedCount) {
        throw new Error('Failed to invite users');
      }

      setUserData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      });
      setShowAddUserForm(false);

      toast({
        title: t('inviteuser-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
         position: 'top-right',
      });
      fetchGroupDataById(selectedGroup.id);
    } catch (error) {
      setError(error.response.data.message);
      toast({
        title: 'Error',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
         position: 'top-right',
      });
    }
  };

  const handleInviteClose = () => {
    setUserData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    setShowAddUserForm(false);
  };


  const handleCompareGroup = (groupId) => {
    const path = `/shortlisted?ids=${groupId}`;
    // Navigate to the constructed path
    navigate(path);
  };


  const handleGroupClick = async (group, e) => {
    if (e.target.type === 'checkbox' || e.target.closest('.chakra-checkbox')) {
      return;
    }
    if (selectedGroup !== group) {
      await fetchGroupDataById(group.id);
    }
    setSelectedGroup(selectedGroup === group ? null : group);
  };


  const handleCheckChange = async (groupId, e) => {
    const isChecked = e.target.checked;
    setCheckedGroups((prev) => ({ ...prev, [groupId]: isChecked }));

    if (isChecked) {
      await savePropertyToGroup(groupId, propertyId);
    } else {
      await removePropertyFromGroup(groupId, propertyId);
    }
  };

  const savePropertyToGroup = async (groupId, propertyId) => {
    try {
      const storedUserInfo = localStorage.getItem('userid');
      const payload = { propertyId };

      const response = await axiosInstance.put(
        `/usrgroup/${groupId}/property`,
        payload
      );
      if (response.data === true) {
        toast({
          title: t('propertysavemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
           position: 'top-right',
        });
      }
    } catch (error) {
      console.error(`Error saving property to group ${groupId}:`, error);
      let errorMessage = t('propertyfailedmessage', { groupId });

      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
         position: 'top-right',
      });
    }
  };

  const removePropertyFromGroup = async (groupId, propertyId) => {
    try {
      const payload = { propertyId };

      const response = await axiosInstance.delete(`/usrgroup/${groupId}/property`, payload );
      toast({
        title: t('propertyremovedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
         position: 'top-right',
      });
    } catch (error) {
      console.error(`Error removing property from group ${groupId}:`, error);
      let errorMessage = t('propertyremovingfailedmessage', { groupId });
      toast({
        title: 'Error',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
         position: 'top-right',
      });
    }
  };

  const handleInviteFriend = (user) => {
    console.log(`Inviting friend for user ${user.name}`);
  };

  const handleUnfriend = async (invitee) => {
    const inviteeEmail = invitee.email;
    const result = await Swal.fire({
      title: 'Are you sure?',
      text:  t('unfriend-confirmmessage', {inviteeEmail}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unfriend!',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'swal2-container',
        popup: 'swal2-popup',
        backdrop: 'swal2-backdrop',
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      }
    });

    if (result.isConfirmed) {
      let urlPath = ''
      if(invitee.user_id){
        urlPath = 'user/'+invitee.user_id;
      }else{
        urlPath = 'user/0?email='+invitee.email;
      }
      try {
        const response = await axiosInstance.delete(`/usrgroup/${selectedGroup.id}/${urlPath}`);

        if (!response.ok) {
          throw new Error('Failed to unfriend user');
        }
        fetchGroupDataById(selectedGroup.id);
        // const updatedInvitees = groupsDataById[selectedGroup.group_id].users.filter((item) => item.email !== invitee.email);
        // setGroupsDataById({
        //   ...groupsDataById,
        //   [selectedGroup.group_id]: {
        //     ...groupsDataById[selectedGroup.group_id],
        //     users: updatedInvitees,
        //   },
        // });

        toast({
          title: `${invitee.email} has been unfriend.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
           position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
           position: 'top-right',
        });
      }
    }
  };


  const handleAddUser = () => {
    setShowAddUserForm(true);
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('inviteshareheader')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
          <Flex 
            align="center" 
            justify="space-between" 
            mb={4} 
            direction={{ base: "column", md: "row" }} // Responsive flex direction
            >
            <Heading size="md" mb={{ base: 4, md: 0 }} ml={2}>
              Groups
            </Heading>
            
            <Stack 
              direction={{ base: "column", md: "row" }} // Stack items in a column for smaller screens
              spacing={4} 
              w={{ base: "100%", md: "auto" }} // Full width on mobile
            >
              <FormControl 
                isInvalid={!groupNameValid || isDuplicateGroupName} 
                isRequired 
                flex="1"
              >
                {/* <Input
                  placeholder="Group name"
                  value={groupName}
                  onChange={handleGroupNameChange}
                  ml={{ base: 0, md: 2 }} 
                  w="100%" 
                /> */}
                 <Menu isOpen={showSuggestions} matchWidth>
                                    <Input
                                      bg="white"
                                      color="gray.500"
                                      fontSize="sm"
                                      px={3}
                                      py={3}
                                      borderRadius="lg"
                                      placeholder="Group Name"
                                      value={groupName}
                                      onChange={handleInputChange}
                                      onKeyDown={handleKeyDown}
                                      style={{
                                        position: 'relative', 
                                        zIndex: 1, 
                                      }}
                                    />
                                    <MenuList 
                                    maxH="500px"
                                    overflowY="auto" 
                                    mt={10}
                                    style={{
                                      position: 'absolute', 
                                      zIndex: 0, 
                                    }}
                                    >
                                  <>
                                  {suggestions.length > 0 ? (
                                    <>
                                      {suggestions.map((suggestion) => (
                                        <MenuItem key={suggestion.companyid} onClick={() => handleSelectSuggestion(suggestion)}>
                                          {suggestion.company_name}
                                        </MenuItem>
                                      ))}
                
                                      {/* Show "New Company Group" only if the entered groupName doesn't exist in suggestions */}
                                      {groupName && !suggestions.some((s) => s.company_name === groupName) && (
                                        <MenuItem
                                          as={Link}
                                          to={`/groupbuyers/company/new?cn=${encodeURIComponent(groupName)}`}
                                          mt={2}
                                          textAlign="center"
                                        >
                                          New Company Group
                                        </MenuItem>
                                      )}
                                    </>
                                  ) : (
                                    // If no suggestions, show "New Company Group"
                                    groupName && (
                                      <MenuItem
                                        as={Link}
                                        to={`/groupbuyers/company/new?cn=${encodeURIComponent(groupName)}`}
                                        mt={2}
                                        textAlign="center"
                                      >
                                        New Company Group
                                      </MenuItem>
                                    )
                                  )}
                                </>
                                    </MenuList>
                                  </Menu>
                <FormErrorMessage>
                  {!groupName.trim() 
                    ? 'Group name is required.' 
                    : groupName.trim().length > 50 
                      ? 'Group name must be 50 characters or less.' 
                      : isDuplicateGroupName 
                        ? 'Group name already exists.' 
                        : null}
                </FormErrorMessage>
              </FormControl>

              <Button
                leftIcon={<AddIcon />}
                size={{ base: "sm", md: "md" }} // Smaller button on mobile
                w={{ base: "100%", md: "200px" }} // Full width on mobile
                colorScheme="blue"
                onClick={handleCreateGroup}
                disabled={groups.length >= MAX_GROUPS}
              >
                Create Group
              </Button>
            </Stack>
          </Flex>

            <Stack spacing={4}>
              {groups && groups.map((group) => (
                <Box key={group.id} position="relative">
                  <Flex
                    align="center"
                    bg="gray.100"
                    p={4}
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{ bg: 'gray.200' }}
                    onClick={(e) => handleGroupClick(group, e)}
                  >
                    <Checkbox
                      isChecked={!!checkedGroups[group.id]}
                      colorScheme="green"
                      onChange={(e) => handleCheckChange(group.id, e)}
                      sx={{ '& .chakra-checkbox__control': { border: '2px solid #3182ce', boxShadow: 'none' } }}
                      onClick={(e) => e.stopPropagation()}
                      mr={5}
                    />
                    <Text flex="1">{group.name}</Text>

                  </Flex>
                  <Box position="absolute" top="15px" right="0px">
                    <Tooltip label={t('groupcomparetooltip')} aria-label={t('groupcomparelabel')}>
                      <IconButton
                        aria-label="Shortlist"
                        icon={<FaExchangeAlt />}
                        colorScheme="blue"
                        size="sm"
                        onClick={() => handleCompareGroup(group.id)}
                      />
                    </Tooltip>
                    <Box position="absolute" top="-12px" right="-4px">
                      <Badge colorScheme="blue">
                        {groupCounts&& groupCounts[group.id]?.property_count || 0}
                      </Badge>
                    </Box>
                  </Box>
                  <Collapse  in={selectedGroup && selectedGroup.id === group.id && 
                    !['Personal'].includes(group.name)} 
                    animateOpacity>
                    <Box p={4}>
                      {!showAddUserForm && (
                        <Button mb={4} colorScheme="blue" onClick={handleAddUser}>
                          Add Invite User
                        </Button>
                      )}

                  {(groupsDataById[group.id]?.users || []).map((invitee, index) => (
                    <Flex 
                      key={index} 
                      p={2} 
                      borderBottomWidth="1px" 
                      align={{ base: "flex-start", md: "center" }} 
                      direction={{ base: "column", md: "row" }} // Stack items on mobile
                      gap={{ base: 2, md: 0 }} // Add space between items on mobile
                    >
                      <Text flex={{ base: "unset", md: "1" }} w={{ base: "100%", md: "auto" }}>
                        {invitee.email}
                      </Text>
                      <Badge
                        colorScheme="blue"
                        size="sm"
                        ml={{ base: 0, md: 2 }}
                        mr={{ base: 0, md: 2 }}
                      >
                        {invitee.status}
                      </Badge>
                      {group.user_id == userId && (
                        <Button 
                          colorScheme="red" 
                          size="sm" 
                          mt={{ base: 2, md: 0 }} // Add top margin on mobile
                          alignSelf={{ base: "flex-start", md: "center" }} // Align button
                          onClick={() => handleUnfriend(invitee)}
                        >
                          Unfriend
                        </Button>
                      )}
                    </Flex>
                  ))}

                      {showAddUserForm && (
                        <Box mt={4}>
                          <Heading size="sm">Add Invite User</Heading>
                          <FormControl mt={2} isRequired isInvalid={!emailValid}>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" name="email" value={userData.email} onChange={handleUserDataChange} />
                            <FormErrorMessage>Email is invalid.</FormErrorMessage>
                          </FormControl>
                          <Button mt={4} colorScheme="blue" onClick={handleInviteUsers}>
                            Submit
                          </Button>
                          <Button mt={4} ml={4} colorScheme="gray" onClick={handleInviteClose}>
                            Cancel
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue"  mr={3} onClick={() => handleGroupCompare({groups})}  bg={buttonColor}>
                      Save
                    </Button> */}
          {/* <Button mr={3}colorScheme="blue" onClick={() => handleGroupSaving({groups})} bg={sbuttonColor}>
        Save to Loveit List
        </Button> */}
          <Button colorScheme="blue" mr={3} onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ShareWithFriendsModal;
