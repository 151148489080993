import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel, createColumnHelper, getPaginationRowModel } from "@tanstack/react-table";
import axiosInstance,{axiosInstanceES} from "../../utils/axio-instance";
import { Button, useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select, Table, Thead, Tbody, Tr, Th, Td, useDisclosure, Tag, TagLabel, TagCloseButton, Wrap } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next'; 
import Swal from "sweetalert2";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CompanyManagement = () => {
  const apiUrl = "/companies";

  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  const [focusedField, setFocusedField] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0); 
  
  const toast = useToast();  
  const { t} = useTranslation();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [validationErrors, setValidationErrors] = React.useState({});
  const [domains, setDomains] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchData = async () => {
    //setLoading(true);
    try {
      const response = await axiosInstanceES.get(apiUrl, {
        params: {
          start: pageIndex*pageSize,
          size: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      const { data: companies, total } = response.data;
      const totalCount = total.value; 

      setData([...companies]);
      setTotalPages(Math.ceil(totalCount / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, columnFilters]);


  const equalsFilterFn = (row, columnId, filterValue) => {
    const cellValue = row.getValue(columnId);
    return filterValue === "" || cellValue.includes(filterValue);
  };

  // Define columns
  const columns = useMemo(
    () => [
       {
              id: 'actions',
              header: "Actions",
              cell: ({ row }) => {
                const isEditing = editRowId === row.original.companyid; // Assuming you have a state like editRowId
            
                return isEditing ? (
                  <div className="flex flex-wrap gap-2 justify-center">
                    <Button 
                    onClick={() => handleUpdateRow(row.original.companyid)}
                    style={{
                      marginRight: '8px',
                      padding: '4px 8px',
                    }}
                    size="sm"
                    >Save</Button>
                    <Button 
                    onClick={handleCancel}
                    style={{
                      padding: '4px 8px',
                    }}
                    size="sm"
                    >Cancel</Button>
                  </div>
                ) : (
                  <div className="flex flex-wrap gap-2 justify-center">
                  <Button 
                   onClick={() => handleEdit(row.original)}
                   style={{
                    marginRight: '8px',
                    padding: '4px 8px',
                  }}
                  size="sm"
                    >Edit</Button>
                  <Button
                    onClick={() => handleDelete(row.original.companyid)}
                    style={{
                      padding: '4px 8px',
                    }}
                    size="sm"
                  >
                    Delete
                  </Button>
                  </div>
                );
              },
            },
      {
        accessorKey: "company_name",
        header: "Company Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Company Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus={focusedField === "company_name"} 
            onFocus={() => setFocusedField("company_name")}  
          />
        ),
        cell: ({ row }) => 
          editRowId === row.original.companyid ? (
            <input
              type="text"
              value={editRowData.company_name || ""}
              onChange={(e) =>
                setEditRowData({ ...editRowData, company_name: e.target.value })
              }
              style={{
                width: "100%",
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                color: "#000",
              }}
              autoFocus
            />
          ) : (
            <span onClick={() => {
              setEditRowId(row.original.companyid);
              setEditRowData(row.original);
            }}>
              {row.original.company_name}
            </span>
          ),
      },
      { 
        accessorKey: "domains",
         header: "Domain",
         filterFn: "includesString",
         Filter: ({ column }) => (
           <input
             value={column.getFilterValue() ?? ""}
             onChange={(e) => column.setFilterValue(e.target.value)}
             placeholder="Filter by Domain"
             style={{
               marginTop: "5px",
               width: "100%",
               padding: "4px",
               border: "1px solid #ccc",
               borderRadius: "4px",
               color: "#000",
             }}
             autoFocus={focusedField === "domains"} 
             onFocus={() => setFocusedField("domains")} 
           />
         ),
         cell: ({ row }) =>
          editRowId === row.original.companyid ? (
            <input
              type="text"
              value={editRowData.domains}
              onChange={(e) =>
                setEditRowData({ ...editRowData, domains: e.target.value })
              }
              style={{
                width: "100%",
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                color: "#000",
              }}
              autoFocus={focusedField === "domains"} 
              onFocus={() => setFocusedField("domains")} 
            />
          ) : (
            <span onClick={() => {
              setEditRowId(row.original.companyid);
              setEditRowData(row.original);
            }}>
             {Array.isArray(row.original.domains) ? row.original.domains.map(d => d.trim()).join(",") : ""}
            </span>
          ),
      },
    ],
    [editRowId, editRowData]
  );

  const table = useReactTable({
    data,
    columns,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
    pageCount: Math.ceil(data.length / pageSize),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false,
    state: {
      globalFilter,
      columnFilters,
      pagination: {
        pageSize,
        pageIndex: 0, // Initial page index
      },
    },
  });

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

  const handleEdit = (rowData) => {
    setEditRowId(rowData.companyid);
    setEditRowData({
      ...rowData
    });
    setFocusedField(null);
  };

  const handleAddNewCompany = () => {
    setEditRowData({ company_name: "",  domains: ""}); // Reset data
    setEditRowId(null);
    setIsModalOpen(true);
  };


   const handleDelete = async (companyid) => {
        const { isConfirmed } = await Swal.fire({
          title: 'Are you sure you want to delete?',
          text: 'You will not be able to recover this Company!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        });
      
        if (isConfirmed) {
          try {
            
              const response = await axiosInstanceES.delete(`/company/${companyid}`);
              if (response.status === 200) {
                setData((prevData) => prevData.filter(row => row.companyid !== companyid));
                toast({
                  title: t('company-deletemessage'),
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  position: 'top-right',
                });
                fetchData();
            }
          } catch (error) {
            console.error('Error deleting Company:', error);
            toast({
              title: 'Error',
              description: t('Error deleting Company'),
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          }
        }
      };
      


  const handleSave = async () => {
    const errors = {};

    if (!editRowData.company_name) {
      errors.company_name = "Company Name is required.";
    } 

  
    if (!editRowData.domain) {
      errors.domain = "Domain is required.";
    }
  
    setValidationErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      try {
        const payload = {
          name: editRowData.company_name,
          domains: editRowData.domain
        };
  
        const response = await axiosInstanceES.put(`/company/add`, payload);
  
        toast({
          title: t('company-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setIsModalOpen(false);
        setFocusedField(null);
        fetchData();
      } catch (error) {
        toast({
          title: 'Error',
            description: t('company-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        });
      }
    }
  };


  const handleCancel = () => {
    setEditRowId(null);
    setEditRowData({});
    setFocusedField(null);
  }

  const checkEmailExistence = async (email) => {
    try {
      const response = await axiosInstance.get(`/users/getUserByEmail?email=${email}`);
  
      if (response.data && response.data.userId) {
        return {
          exists: true, // Email exists if userId is present
          userid : response.data.userId,
          role: response.data.roles || null, // Extract role or default to null
        };
      }
  
      // Default return if userId is not present
      return { exists: false, role: null };
    } catch (error) {
      console.error("Error checking email existence:", error);
      return { exists: false, role: null }; // Return default on error
    }
  };
  

  const handleUpdateRow = async (companyid) => {
    let errors = [];

  // Validate Referral Status
  if (!editRowData.company_name) {
    errors.company_name = "Company Name is required.";
  } 

  if (!editRowData.domain) {
    errors.domain = "Domain Name is required.";
  } 


      try {
        const payload = {
         name: editRowData.company_name,
         domains: editRowData.domains,
         companyId: editRowData.companyid,
        };
  
        const response = await axiosInstanceES.put(`/company/update/`, payload);
        console.log("updatepayload", payload);
  
        toast({
          title: t('company-successmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setIsModalOpen(false);
        setEditRowId(null);
        setFocusedField(null);
        fetchData();
      } catch (error) {
        toast({
          title: 'Error',
            description: t('company-failuremessage'),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
        });
      }
  };
  

  useEffect(() => {
    setDomains(editRowData.domain ? editRowData.domain.split(",") : []);
  }, [editRowData]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const newDomains = inputValue
        .split(",")
        .map((d) => d.trim())
        .filter((d) => d && !domains.includes(d));

      if (newDomains.length) {
        const updatedDomains = [...domains, ...newDomains];
        setDomains(updatedDomains);
        setEditRowData((prev) => ({
          ...prev,
          domain: updatedDomains.join(","), // Store as comma-separated string in editRowData
        }));
      }
      setInputValue(""); // Clear input
    }
  };

  const handleRemoveTag = (domainToRemove) => {
    const updatedDomains = domains.filter((domain) => domain !== domainToRemove);
    setDomains(updatedDomains);
    setEditRowData((prev) => ({
      ...prev,
      domain: updatedDomains.join(","), // Update editRowData after removing a tag
    }));
  };

  // Pagination Range Logic
  const getPaginationRange = () => {
    const visiblePages = 5; // Show 5 page buttons at most
    const pages = [];

    // Add first page
    if (pageIndex > 2) {
      pages.push(0);
      if (pageIndex > 3) pages.push("...");
    }

    // Add pages around the current page
    for (
      let i = Math.max(pageIndex - 2, 0);
      i <= Math.min(pageIndex + 2, totalPages - 1);
      i++
    ) {
      pages.push(i);
    }

    // Add last page
    if (pageIndex < totalPages - 3) {
      if (pageIndex < totalPages - 4) pages.push("...");
      pages.push(totalPages - 1);
    }

    return pages;
  };

  const paginationRange = getPaginationRange();

  return (
    <div className="bg-primary font-linksans min-h-svh">
    <div className="w-full px-5 py-12">
    <h3 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-4xl font-bold text-textColor pb-10">
    {t("company_mgt_title")}
        </h3>

        <div className="flex justify-start mb-4">
                    <Button style={{borderWidth:"1px", borderColor: '#3b82f6', borderRadius:"8px" }} onClick={handleAddNewCompany} colorScheme="blue">
                    {t("add_new_company")}
                    </Button>
                  </div>
        

      {loading ? (
        <p>{t("loading")}</p>
      ) : (
        <div className="overflow-x-auto">
          <table
           className="w-full border border-gray-300 bg-gray-50"
           style={{
             borderCollapse: "collapse",
           }}
          >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="border-b bg-slate-300 border-gray-300 text-center p-2"
                style={{
                  minWidth: "150px", // Adjust this width as needed for better responsiveness
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div className="mt-1">
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
      {table.getRowModel().rows.length > 0 ? (
        table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                 className="border-b border-gray-300 p-2 text-center"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td
            colSpan={table.getAllColumns().length}
            className="p-4 text-center text-gray-500"
          >
            {t("no_company_message")}
          </td>
        </tr>
      )}      
      </tbody>
          </table>


          
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Button
          onClick={handlePreviousPage}
          disabled={pageIndex === 0}
          style={{ marginRight: "10px" }}
        >
           <FaChevronLeft />
        </Button>

        {paginationRange.map((page, idx) =>
          page === "..." ? (
            <span key={idx} style={{ margin: "0 5px" }}>
              ...
            </span>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              style={{
                marginRight: "5px",
                backgroundColor: pageIndex === page ? "blue" : "gray",
                color: pageIndex === page ? "gray" : "black",
              }}
            >
              {page + 1}
            </Button>
          )
        )}

        <Button
          onClick={handleNextPage}
          disabled={pageIndex >= totalPages - 1}
          style={{ marginLeft: "10px" }}
        >
         <FaChevronRight />
        </Button>
      </Box>

       <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Companies</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Input
                placeholder="company Name"
                value={editRowData.company_name || ""}
                onChange={(e) =>
                  setEditRowData((prev) => ({
                    ...prev,
                    company_name: e.target.value,
                  }))
                }
              />
              {validationErrors.company_name && (
                <Text color="red.500" fontSize="sm">
                  {validationErrors.company_name}
                </Text>
              )}
            </Box>
            <Box mb={4}>
              <Input
                placeholder="Enter domains (comma-separated)"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
              />
              <Wrap mt={2}>
                  {domains.map((domain, index) => (
                    <Tag key={index} size="md" variant="solid" colorScheme="blue">
                      <TagLabel>{domain}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(domain)} />
                    </Tag>
                  ))}
                </Wrap>
              {validationErrors.domain && (
                <Text color="red.500" fontSize="sm">
                  {validationErrors.domain}
                </Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave} mr={3}>
              Save
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Page Size Selector */}
      <Box mt={4} display="flex" justifyContent="center" alignItems="center">
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
          width="auto"
          style={{ marginLeft: "10px" }}
        >
          {[10, 20, 50, 100, 500].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
      </Box>

{/* 
          <div style={{ marginTop: "10px" }}>
            Page {pageIndex + 1} of {totalPages} ({data.length} records on this
            page)
          </div> */}
        </div>
      )}
    </div>
    </div>
  );
};

export default CompanyManagement;
