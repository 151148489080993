import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { filters } from '../../data/Filters';

import axiosInstance, {axiosInstanceES} from '../../utils/axio-instance';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../user/AuthProvider';

const ProductsContext = createContext();

export const useProducts = () => useContext(ProductsContext);

const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [suggestionProducts, setSuggestionProducts] = useState([]);
  const [groupProductCounts, setGroupProductCounts] = useState();
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState({});
  const location = useLocation();

  const [userId, setUserId] = useState(null);
  const [productGroups , setProductGroups] = useState({});
  const [checkedGroupsByProperty, setCheckedGroupsByProperty] = useState({});
  const[groupsUpdated,setGroupsUpdated] = useState(false);
  const { checkLogIn,user } = useAuth(); 
 
  const checkLoggedIn = () => {
    return !!localStorage.getItem('userid');
  };

  const [viewProducts, setviewProducts] = useState(() => {
    if (checkLoggedIn()) {
      const localViewedList = JSON.parse(localStorage.getItem('viewedlist') || '[]')
      return localViewedList;
    }
    return [];
  });

  const fetchGroups = async () => {
    try {
      const storedGroups = localStorage.getItem('usrgroups');
      if (storedGroups) {
        setGroups(JSON.parse(storedGroups));
        return;
      }
     // console.log("fetching groups from api");
      const response = await axiosInstance.get(`/usrgroup/`);

      // if (!response.ok) {
      //   const errorText = await response.text();
      //   throw new Error(`Failed to fetch groups: ${response.status} ${errorText}`);
      // }

      const responseData = response.data
      if (responseData && responseData.length > 0) {
        setGroups(responseData);
        localStorage.setItem('usrgroups', JSON.stringify(responseData));
      }
    } catch (error) {
      console.error('Error fetching groups:', error);
      setError(error.message);
    }
  };

 
  const addPropertyToGroup = async (groupId, propertyId) => {
    try {
      const response = await axiosInstance.put(`/usrgroup/${groupId}/property`,
        { propertyId }
      );
      if (response.statusText === 'OK') {
        setGroupsUpdated(!groupsUpdated);
        setCheckedGroups((prev) => ({
          ...prev,
          [groupId]: [...(prev[groupId] || []), propertyId],
        }));
        fetchGroupCounts();
      }
      else{
        throw new error("Failed to add property to group")
      }
    } catch (error) {
      console.error(`Error adding property to group:`, error);
      throw error;
    }
  };

  // Remove property from a group
  const removePropertyFromGroup = async (groupId, propertyId) => {
    try {
      const payload = { "propertyId" : propertyId};

      const response = await axiosInstance.delete(`/usrgroup/${groupId}/property`, {data:payload} );

      // here based on response success we need to set
      if (response.status === 200 || response.status === 204) { 
        setGroupsUpdated(!groupsUpdated);
        setCheckedGroups((prev) => {
          const groupProperties = prev[groupId] || []; 
          return {
            ...prev,
            [groupId]: groupProperties.filter((id) => id !== propertyId),  
          };
        });
        fetchGroupCounts();
        return true;
      } else {
        throw new Error('Failed to remove property from group');
      }
    } catch (error) {
      console.error(`Error removing property from group:`, error);
      throw error;
    }
  };



  const isPropertyInGroup = (groupId, propertyId) => {
    return Array.isArray(checkedGroups[groupId]) && checkedGroups[groupId].includes(propertyId);
  };

const groupGroutsLoading = useRef(false);

  useEffect(() => {
   // console.log("change userid", userId);
    const storedGroups = localStorage.getItem('usrgroups');
    if (!storedGroups && userId) {
     // console.log("useeffect checkedGroups");
      fetchGroups();
      fetchGroupCounts();
    }
  }, [userId, location.search]);
  

  const fetchGroupCounts = async()=>{
    try {
      if(groupGroutsLoading.current) return;
      groupGroutsLoading.current = true;
      const response = await axiosInstance.get(`/usrgroup/all/count`);
      const responseData = response.data;
      setGroupProductCounts(responseData);
    } catch (error) {
      setError(error.message);
    }
    finally{
      groupGroutsLoading.current = false;
    }
  }

  useEffect(() => {
    // Check if user ID exists in local storage
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  
  useEffect(() => {
      const fetchData = async () => {
        // Extract the ID from the URL query parameters
        const searchParams = new URLSearchParams(location.search);
        const ids = searchParams.get('ids'); // Assuming 'ids' contains the required ID
        if (ids) {
          const idArray = ids.split(',');
          const lastId = idArray[idArray.length - 1];
    
          try {
            // First API call to get the details of the last ID
            const detailsResponse = await axiosInstance.get(`/usrgroup/${lastId}/properties`);
            //const { compare_list: compareList } = detailsResponse.data.property_id;
            //const lastCompareId = compareList.length > 0 ? compareList[compareList.length - 1] : null;
            const compareList = Object.keys(detailsResponse.data).map(id => Number(id));
            //console.log("compareList",compareList);
            const lastCompareId = compareList.length > 0 ? compareList[compareList.length - 1] : null;
            const propertyIdsCommaSeparated = compareList.join(',');
            const propertyIdsArray = propertyIdsCommaSeparated.split(',');
            // console.log("lastCompareId1",lastCompareId);
            setSelectedProducts(propertyIdsArray);
    
          
            // Second API call to get the user properties based on the compare list
            if (lastCompareId) {
              const suggestionProductsResponse = await axiosInstanceES.get(`/nearbyproperty/${lastCompareId}`);
              setSuggestionProducts(suggestionProductsResponse.data); // Assuming the API returns an array of products
            } else {
              setSuggestionProducts([]); // Optionally clear the suggestions if there's no lastCompareId
            }

            const compareListingsResponse = await axiosInstanceES.get(`/userpropertyarraydata`, {
              params: { ids: compareList.join(',') }
            });
    
            setProducts(compareListingsResponse.data); // Assuming the API returns an array of products
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        }
      };
      
      if (checkLoggedIn()) {
      fetchData();
    }
     else {
      const searchParams = new URLSearchParams(location.search);
      const ids = searchParams.get('ids'); // Assuming 'ids' contains the required ID
      if (ids) {
      const idArray = ids.split(',');
      const lastId = idArray[idArray.length - 1];
      if (lastId) {
        axiosInstanceES
          .get(`/nearbyproperty/${lastId}`)
          .then((response) => {
            setSuggestionProducts(response.data);
          })
          .catch((error) => {
            console.error("Error fetching suggestion products:", error);
          });
      } else {
        setSuggestionProducts([]);
      }
    }
     }
  }, [userId,location.search]);
  
   const [selectedProducts, setSelectedProducts] = useState([]);


  const [numChanges, setNumChanges] = useState(0);

  const fetchListsFromServer = async (page = 0, size = 10) => {
    if (checkLoggedIn()) {
        try {
          // const url = new URL(`/usrgroup/all/properties`);
          // url.searchParams.append('from', page * size);
          // url.searchParams.append('size', size);
          //const listsResponse = await axiosInstance.get(url);

          const listsResponse= await axiosInstance.get('/usrgroup/all/properties', {
            params: {
              from: page * size, // pagination: 'from' query parameter
              size: size,        // pagination: 'size' query parameter
            },
          });

          // Fetch user lists with pagination parameters
        
    
          // Handle user lists API response
          const userData = await listsResponse.data;
          const propertyIdsArray = Object.keys(userData).map(id => Number(id)); // Ensure they are numbers
         const mergedLoveList = [];
             const mergedProductList = mergedProductLists(selectedProducts, propertyIdsArray);
            // const mergedProductList = mergedProductLists(selectedProducts, userData.map(item=>Number(item)))
             const mergedViewList = [];
              
              setSelectedProducts(mergedProductList);
              
        } catch (error) {
            console.error('API error:', error);
        }
    }
}


  const saveLists = async () => {
    // Load user info from local storage
    const storedUserInfo = localStorage.getItem('userid');
    //setUserInfo(storedUserInfo);

    if (checkLoggedIn() && selectedProducts.length > 0) {
      axiosInstance.post(`/usrgroup/Personal/addproperies`, {
        "properties": selectedProducts,
      }).then((response) => {
          localStorage.removeItem('ProductsList');
        })
        .catch(error => {
          console.error('API error:', error);
        });
    }
  }

  useEffect(()=>{
    if (checkLoggedIn() && selectedProducts.length > 0) {
      saveLists();
    }
  },[checkLoggedIn()])

  const mergeLovedLists = (localWishList, serverWishList) => {
    if (!localWishList || localWishList.length === 0) { return serverWishList }
    if (!serverWishList || serverWishList.length === 0) { return localWishList }
    const mergedLovedListIds = new Set([...localWishList, ...serverWishList])
    return Array.from(mergedLovedListIds)
  }

  const mergedProductLists = (localWishList, serverWishList) => {
    if (!localWishList || localWishList.length === 0) { return serverWishList }
    if (!serverWishList || serverWishList.length === 0) { return localWishList }
    const mergedProductListIds = new Set([...localWishList, ...serverWishList])
    return Array.from(mergedProductListIds)
  }

  const mergedViewLists = (localWishList, serverWishList) => {
    if (!localWishList || localWishList.length === 0) { return serverWishList }
    if (!serverWishList || serverWishList.length === 0) { return localWishList }
    const mergedViewListIds = new Set([...localWishList, ...serverWishList])
    return Array.from(mergedViewListIds)
  }


  useEffect(() => {
    if (checkLoggedIn()) {
     fetchListsFromServer();
    } else {
      // const localLovedList = JSON.parse(localStorage.getItem('lovedlist') || '[]')
      // setLovedProducts(localLovedList);
      const localProductsList = JSON.parse(localStorage.getItem('ProductsList') || '[]')
     
      setSelectedProducts(localProductsList);
      // const localViewedList = JSON.parse(localStorage.getItem('viewedList') || '[]')
      // setviewProducts(localViewedList);
    }
  }, [checkLoggedIn()])

  useEffect(() => {
    if (selectedProducts && selectedProducts.length > 0 && checkLogIn()) {
      localStorage.setItem('ProductsList', JSON.stringify(selectedProducts))
    }
  }, [selectedProducts])

  const addProductToSelection = productId => {
    setSelectedProducts(prevSelectedProducts => {
      if (!Array.isArray(prevSelectedProducts)) {
        return [productId];
      }
      return [...prevSelectedProducts, productId];
    });
    setNumChanges(prevNumChanges => prevNumChanges + 1);
  };

  const removeProductFromSelection = productIdToRemove => {
    setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(productId => productId != productIdToRemove));
    setNumChanges(prevNumChanges => prevNumChanges + 1)
  };
  const addProductGroups = productObj => {
    setProductGroups(prevSelGroups => {
      return {...prevSelGroups, ...productObj};
    });
    //setNumChanges(prevNumChanges => prevNumChanges + 1);
  };

  const addViewProductToSelection = productId => {
    setviewProducts(previewProducts => {
      if (!Array.isArray(previewProducts)) {
        return [productId];
      }
      return [...previewProducts, productId];
    });
    setNumChanges(prevNumChanges => prevNumChanges + 1);
  };

  return (
    <ProductsContext.Provider
      value={{ products, filters, selectedProducts, addProductToSelection, removeProductFromSelection, 
        addViewProductToSelection, suggestionProducts, groupProductCounts,
        groups,
        checkedGroups,
        setCheckedGroups,
        addPropertyToGroup,
        removePropertyFromGroup,
        isPropertyInGroup,
        checkedGroupsByProperty, 
        groupsUpdated,
        fetchGroups
       }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsProvider;
