import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel } from "@tanstack/react-table";
import axiosInstance from "../../utils/axio-instance";
import { Button, useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, Text, Select, Table, Thead, Tbody, Tr, Th, Td, useDisclosure } from '@chakra-ui/react';
import { roles, StatusOptions} from '../../constants';
import { useTranslation } from 'react-i18next'; 
import Swal from "sweetalert2";



const UserManagement = () => {
  const apiUrl = "/users/list";

  // State management
  const [data, setData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [userPreferences, setUserPreferences] = useState(null);
  const [editRowId, setEditRowId] = useState(null);
  const [editRowData, setEditRowData] = useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [columnFilters, setColumnFilters] = React.useState([]);
  
  const toast = useToast();  
  const { t} = useTranslation();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [validationErrors, setValidationErrors] = React.useState({});

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(apiUrl, {
        params: {
          from: pageIndex*pageSize,
          size: pageSize,
          filters: columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {}),
        },
      });

      const { data: users, total } = response.data;
      console.log("usersdata",response.total);
      setData(users);
      setTotalPages(Math.ceil(total / pageSize));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  // Fetch data from server
  useEffect(() => {
    fetchData();
  }, [apiUrl, pageIndex, pageSize, columnFilters]);

  // Define columns
  const columns = useMemo(
    () => [
      {
        id: 'actions',
        header: "Actions",
        cell: ({ row }) => {
          const isEditing = editRowId === row.original.user_id; // Assuming you have a state like editRowId
      
          return isEditing ? (
            <>
              <Button 
              onClick={() => handleUpdate(row.original.user_id)}
              style={{
                marginRight: '8px',
                padding: '4px 8px',
              }}
              size="sm"
              >Save</Button>
              <Button 
              onClick={handleCancel}
              style={{
                padding: '4px 8px',
              }}
              size="sm"
              >Cancel</Button>
            </>
          ) : (
            <>
            <Button 
             onClick={() => handleEdit(row.original)}
             style={{
              marginRight: '8px',
              padding: '4px 8px',
            }}
            size="sm"
              >Edit</Button>
            <Button
              onClick={() => handleDelete(row.original.user_id)}
              style={{
                marginRight: '8px',
                padding: '4px 8px',
              }}
              size="sm"
            >
              Delete
            </Button>
            <Button 
              onClick={() => handleInfoClick(row.original.user_id)}
              colorScheme="green" 
              style={{
                padding: '4px 8px',
              }} 
              size="sm">Info</Button>
            </>
          );
        },
      },
      {
        accessorKey: "first_name",
        header: "First Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by First Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.user_id ? (
            <input
              type="text"
              value={editRowData.first_name || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, first_name: e.target.value }))
              }
              placeholder="Edit First Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus
            />
          ) : (
            row.original.first_name
          ),
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
        filterFn: "includesString",
        Filter: ({ column }) => (
          <input
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            placeholder="Filter by Last Name"
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
            autoFocus 
          />
        ),
        cell: ({ row }) =>
          editRowId === row.original.user_id ? (
            <input
              type="text"
              value={editRowData.last_name || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, last_name: e.target.value }))
              }
              placeholder="Edit Last Name"
              style={{
                width: "100%", 
                height: "100%",
                border: "1px solid #ccc", 
                borderRadius: "4px", 
                padding: "4px 8px",
                boxSizing: "border-box",
              }}
              autoFocus
            />
          ) : (
            row.original.last_name
          ),
      },
      { 
        accessorKey: "email",
         header: "Email",
         filterFn: "includesString",
         Filter: ({ column }) => (
           <input
             value={column.getFilterValue() ?? ""}
             onChange={(e) => column.setFilterValue(e.target.value)}
             placeholder="Filter by Email"
             style={{
               marginTop: "5px",
               width: "100%",
               padding: "4px",
               border: "1px solid #ccc",
               borderRadius: "4px",
               color: "#000",
             }}
             autoFocus 
           />
         ),
        
        },
      {
        accessorKey: "roles",
        header: "Roles",
        filterFn: "equals",
        Filter: ({ column }) => (
          <select
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
        ),
        cell: ({ row }) =>
          editRowId === row.original.user_id ? (
            <select
            value={editRowData.roles || ""}
              onChange={(e) =>
                setEditRowData((prev) => ({ ...prev, roles: e.target.value }))
              }
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
          ) : (
            row.original.roles.join(", ") // Display roles as a comma-separated list
          ),
      },
      { 
        accessorKey: "profile_status", 
        header: "Status",
        filterFn: "equals",
        Filter: ({ column }) => (
          <select
            value={column.getFilterValue() ?? ""}
            onChange={(e) => column.setFilterValue(e.target.value)}
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {StatusOptions.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
        ),
        cell: ({ row }) =>
          editRowId === row.original.user_id ? (
            <select
            value={editRowData.profile_status || ""}
            onChange={(e) =>
              setEditRowData((prev) => ({ ...prev, profile_status: e.target.value }))
            }
            style={{
              marginTop: "5px",
              width: "100%",
              padding: "4px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              color: "#000",
            }}
          >
            <option value="">All</option>
            {StatusOptions.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </select>
          ) : (
            row.original.profile_status // Display roles as a comma-separated list
          ),
      },
    ],
    [editRowId, editRowData]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      columnFilters,
    },
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    manualFilters: true,
    getFilteredRowModel: getFilteredRowModel(),
  });

  // Handle pagination changes
  const handlePageClick = (page) => setPageIndex(page);

  const handlePreviousPage = () => {
    if (pageIndex > 0) setPageIndex((prev) => prev - 1);
  };

  const handleNextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex((prev) => prev + 1);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(Number(e.target.value));
    setPageIndex(0);
  };

  // Edit Handlers
  const handleEdit = (rowData) => {
    setEditRowId(rowData.user_id);
    setEditRowData(rowData);
  };

  const handleCancel = () => {
    setEditRowId(null);
    setEditRowData({});
  };

  const handleSave = async () => {
    const errors = {};
    
    // Validate Property ID
    if (!editRowData.first_name) {
      errors.first_name = "First Name is required.";
    }

    if (!editRowData.last_name) {
      errors.last_name = "Last Name is required.";
    }
  
    if (!editRowData.email) {
      errors.email = "Email is required.";
    }

    if (!editRowData.role) {
      errors.role = "Role is required.";
    }
  
    setValidationErrors(errors);
  
    if (Object.keys(errors).length === 0) {
      try {
        const payload = {
          first_name: editRowData.first_name,
          last_name: editRowData.last_name,
          email: editRowData.email,
          roles: Array.isArray(editRowData.role) ? editRowData.role : [editRowData.role],
          password: "Next@123",
          legal_acceptance: "true",
        };
  
        const response = await axiosInstance.post(`/users/create-user/`, payload);
  
        toast({
          title: t('user-addedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setIsModalOpen(false);
        fetchData();
      } catch (error) {
        toast({
          title: 'Error',
          description:  `There was an error adding the user: ${error.message}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleUpdate = async (userId) => {
      try {
        const payload = {

          first_name: editRowData.first_name,
          last_name: editRowData.last_name,
          roles: Array.isArray(editRowData.roles) ? editRowData.roles : [editRowData.roles],
          profile_status: editRowData.profile_status,
        };
  
        const response = await axiosInstance.put(`/users/${userId}/updaterole`, payload);
  
        toast({
          title: t('user-updatedmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        setEditRowId(null);
        fetchData();
      } catch (error) {
        toast({
        title: 'Error',
        description:  `There was an error adding the user: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        });
      }
  };
  
  const handleInfoClick = async (userId) => {
    try {
      onOpen();
      const response = await axiosInstance.get(`/users/${userId}/profile/`);
      setUserProfile(response.data);

      const preferencesResponse = await axiosInstance.get(`/users/${userId}/preferences/`);
      setUserPreferences(preferencesResponse.data);
     
    } catch (error) {
      console.error('Error fetching user profile:', error);
      //TODO: add toaster
      onClose();
    }
  };

  const handleDelete = async (user_id) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure you want to delete?',
      text: 'You will not be able to recover this user!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });
  
    if (isConfirmed) {
      try {
        // Check if the row is a new row (not yet saved in the database)
        const isNewRow = data === user_id;
  
        if (isNewRow) {
          setData(data.filter(row => row.user_id !== user_id));
          toast({
            title: t('user-removemessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        } else {
          const response = await axiosInstance.delete(`/users/${user_id}`, {
            
          });
  
          if (response.status === 200) {
            setData((prevRowData) => prevRowData.filter(row => row.user_id !== user_id));
            //gridOptions.api.refreshInfiniteCache();
            toast({
              title:  t('user-deletemessage'),
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top-right',
            });
            fetchData();
          } else {
            throw new Error('Failed to delete user');
          }
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast({
          title: 'Error',
          description: t('user-failed'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      }
    }
  };

  return (
    <div className="bg-primary  font-linksans">
    <div className="container px-5 py-12">
    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
    User Management
        </h3>

      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '16px',borderWidth:"1px", borderColor: '#3b82f6', borderRadius:"8px"  }}>
            <Button onClick={() => setIsModalOpen(true)} colorScheme="blue">
              Add New User
            </Button>
          </div>
          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              border: '1px solid #ddd',
              background :'#fafbfc',
            }}
          >
            <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{
                  borderBottom: '1px solid #ddd',
                  width:'15%',
                  padding: '8px',
                  textAlign: 'center',
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}

                 {/* Column Filters */}
                 {header.column.getCanFilter() ? (
                    <div style={{ marginTop: "5px" }}>
                      {flexRender(
                        header.column.columnDef.Filter,
                        header.getContext()
                      )}
                    </div>
                  ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                style={{
                  borderBottom: '1px solid #ddd',
                  padding: '4px',
                  textAlign: 'center',
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
          </table>

          {/* Add user Modal */}

          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="2xl" isCentered>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Add User</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Box mb={4}>
        <Input
          placeholder="First Name"
          value={editRowData.first_name || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              first_name: e.target.value,
            }))
          }
        />
        {validationErrors.first_name && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.first_name}
          </Text>
        )}
      </Box>
      <Box mb={4}>
        <Input
          placeholder="Last Name"
          value={editRowData.last_name || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              last_name: e.target.value,
            }))
          }
        />
        {validationErrors.last_name && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.last_name}
          </Text>
        )}
      </Box>
      <Box mb={4}>
        <Input
          placeholder="Email"
          value={editRowData.email || ""}
          onChange={(e) =>
            setEditRowData((prev) => ({
              ...prev,
              email: e.target.value,
            }))
          }
        />
        {validationErrors.email && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.email}
          </Text>
        )}
      </Box>
      <Box mb={4}>
      <Select
            placeholder="Select Role"
            value={editRowData.role || ""} // Bind the selected role to `editRowData.role`
            onChange={(e) =>
              setEditRowData((prev) => ({
                ...prev,
                role: e.target.value, // Save the selected role value
              }))
            }
          >
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label} {/* Show the human-readable label */}
              </option>
            ))}
          </Select>

        {validationErrors.role && (
          <Text color="red.500" fontSize="sm">
            {validationErrors.role}
          </Text>
        )}
      </Box>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" onClick={handleSave} mr={3}>
        Save
      </Button>
      <Button variant="ghost" onClick={() => setIsModalOpen(false)}>
        Cancel
      </Button>
    </ModalFooter>
  </ModalContent>
         </Modal>

         {/* Profile Information Modal */}

         <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Profile Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {userProfile ? (
              <Table variant="simple" mb={2}>
                <Thead>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Full Name</Td>
                    <Td>{(userProfile.first_name || userProfile.last_name) ? `${userProfile.first_name || ''} ${userProfile.last_name || ''}` : null}</Td>
                  </Tr>
                  <Tr>
                    <Td>Email</Td>
                    <Td>{userProfile.email}</Td>
                  </Tr>
                  <Tr>
                    <Td>Phone Number</Td>
                    <Td>{userProfile.mobile}</Td>
                  </Tr>
                  <Tr>
                    <Td>Alternate Number</Td>
                    <Td>{userProfile.phone_2}</Td>
                  </Tr>
                  <Tr>
                    <Td>Address</Td>
                    <Td>{userProfile.primary_address}</Td>
                  </Tr>
                </Tbody>
              </Table>
            ) : (
              <p>Loading...</p> // Fallback if profile data isn't available yet
            )}

         {userPreferences && (
              <>
                <h3>User Preferences</h3>
                <Table variant="simple" mt={2}>
                  <Tbody>
                    <Tr>
                      <Td>Preferred Location</Td>
                      <Td>{userPreferences.preferredLocation}</Td>
                    </Tr>
                    <Tr>
                      <Td>Preferred Property Types</Td>
                      <Td>{userPreferences.preferredPropertyTypes.join(', ')}</Td>
                    </Tr>
                    <Tr>
                      <Td>Preferred Price Range</Td>
                      <Td>{userPreferences.preferredPriceRange.join(' - ')}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

          {/* Pagination Controls */}
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <div style={{ marginBottom: "10px" }}>
              <label htmlFor="pageSize">Rows per page: </label>
              <select
                id="pageSize"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
              </select>
            </div>

            <Button
              onClick={handlePreviousPage}
              disabled={pageIndex === 0}
              style={{ marginRight: "10px" }}
            >
              Previous
            </Button>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i}
                onClick={() => handlePageClick(i)}
                style={{
                  marginRight: "5px",
                  backgroundColor: pageIndex === i ? "blue" : "gray",
                  color: pageIndex === i ? "gray" : "black",
                }}
              >
                {i + 1}
              </Button>
            ))}
            <Button
              onClick={handleNextPage}
              disabled={pageIndex >= totalPages - 1}
              style={{ marginLeft: "10px" }}
            >
              Next
            </Button>
          </div>

          <div style={{ marginTop: "10px" }}>
            Page {pageIndex + 1} of {totalPages} ({data.length} records on this
            page)
          </div>
        </>
      )}
    </div>
    </div>
  );
};

export default UserManagement;
