import React from "react";
import { useLocation } from "react-router-dom";
import { userProgressLinks } from "../../constants";

const ProgressBarWithoutSignin = ({ step , pagerole }) => {
  const location = useLocation();
  const match = location.pathname.match(/^\/(groupbuyers|buyer|builder|agent)/);
  const role = match ? match[1] : pagerole || "buyer";
  const user_flag = 1;

  // Use `step` prop if provided, otherwise fall back to user_flag
  const activeStep = step ?? user_flag;

  const steps = userProgressLinks[role] || {};
  const stepsArray = Object.entries(steps).map(([key, value]) => ({
    step: parseInt(key),
    label: value.label,
    path: value.path,
  }));

  // Determine active step index
  const activeStepIndex = stepsArray.findIndex((s) => s.step === activeStep);

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      {/* Progress Bar */}
      <div className="relative flex items-center justify-between">
        {stepsArray.map((step, index) => (
          <div key={index} className="relative flex flex-col items-center w-full">
            {/* Step Circle with Tooltip */}
            <div className="relative flex flex-col items-center group">
              <div
                className={`flex items-center justify-center w-8 h-8 rounded-full cursor-pointer peer ${
                  activeStepIndex >= index ? "bg-green-600" : "bg-gray-400"
                }`}
              >
                <span className="text-white text-sm z-50">{step.step}</span>
              </div>

              {/* Tooltip */}
              <div className="min-w-[100px] absolute top-8 bg-black text-white text-xs px-2 py-1 rounded-md opacity-0 transition-opacity group-hover:opacity-100 peer-focus:opacity-100">
                {step.label}
              </div>
            </div>

            {/* Step Label */}
            <span
              className={`mt-2 text-center text-sm hidden sm:block ${
                activeStepIndex >= index ? "text-green-600" : "text-gray-500"
              }`}
            >
              {step.label}
            </span>

            {/* Connecting Line */}
            {index < stepsArray.length - 1 && (
              <div
                className={`absolute top-[26%] left-full h-[2px] w-full ${
                  activeStepIndex >= index ? "bg-green-600" : "bg-gray-400"
                }`}
                style={{ transform: "translateX(-50%)" }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBarWithoutSignin;
