import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, Center, VStack, Heading, Text, Divider,Button } from '@chakra-ui/react'; // Import Chakra UI components
import ProductList from './ProductList';
import ComparisonTable from './ComparisonTable';
import Breadcrumb from '../Breadcrumb';
import { useNavigate, useLocation } from 'react-router-dom';
import AutocompleteContainer from './AutocompleteContainer';
import { useProducts } from './ProductsProvider';
import CompareText from './CompareText';
import SignUpProgress from '../user/SignUpProgress';


const ProductComparison = (props) => {
    const [autocompleteData, setAutocompleteData] = useState([]);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [userId, setUserId] = useState(null);
    const checkLoggedIn = () => {
        return !!localStorage.getItem('userid');
      };
    const searchParams = new URLSearchParams(location.search);
    const idsdata = searchParams.get('ids');
    let lastId;
    if (idsdata) {
        const idArray = idsdata.split(',');
        lastId = idArray[idArray.length - 1];
    }
  
    const { products, filters, selectedProducts, addProductToSelection, removeProductFromSelection } = useProducts();

    useEffect(() => {
        setData(selectedProducts);
    }, [selectedProducts]);

    useEffect(() => {
    // Check if user ID exists in local storage
    const storedUserId = localStorage.getItem('userid');
    if (storedUserId) {
        setUserId(storedUserId);
    }
    }, []);

    const addToCompare = (productid) => {
        addProductToSelection(productid); 
    };
    const removeFromCompare = (productid) => {
        removeProductFromSelection(productid); 
    };
    const handleAutocompleteData = (data) => {
      setAutocompleteData(data); 
    };
  
    return (
        <div className="bg-primary font-linksans pb-12">
         <div className='container'>
             <Breadcrumb/>
             {userId && 
             <SignUpProgress/>
             }
            <AutocompleteContainer onAutocompleteData={handleAutocompleteData}  />

            {data.length === 0 && <ComparisonTable/>} 
            {(data.length >= 1 && !checkLoggedIn() ) &&  <ComparisonTable ids={selectedProducts} filters={filters} />}
            {(data.length >= 1 && checkLoggedIn() ) &&  <ComparisonTable ids={lastId} filters={filters} />}
            {data.length >= 1  &&  <ProductList autocompletedata={autocompleteData || products} selected={products || []} addToCompare={addToCompare} removeFromCompare={removeFromCompare} />}
           
        </div>
        </div>
    );
};

export default ProductComparison;
