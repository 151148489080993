import React, { useState, useEffect, useRef } from 'react';
import {
  ChakraProvider, Box, Flex, Heading,
  Button, Input, Stack,
  Text, IconButton, Collapse,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Spinner,
  Tooltip,
  Icon,
  useToast,
  Tabs,
  TabList, 
  Tab,
  Menu,
  MenuButton,
  MenuList, 
  MenuItem
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, CopyIcon } from '@chakra-ui/icons';
import { FaExchangeAlt, FaSignOutAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance,{axiosInstanceES} from '../../utils/axio-instance';
import CommonStatusButton from "../../pages/InvitePage/CommonStatusButton"; 
import CreateGroupModal from './CreateGroupModal';
import InviteModal from './InviteModal';
import SignUpProgress from './SignUpProgress';
import { FaTrashAlt, FaCopy, FaCaretRight } from "react-icons/fa";
import InviteTable from './InviteTable';
import { Link } from 'react-router-dom';

function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [groupsDataById, setGroupsDataById] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupNameValid, setGroupNameValid] = useState(true);
  const [isDuplicateGroupName, setIsDuplicateGroupName] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAddUserForm, setShowAddUserForm] = useState(false);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("Invites");
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);
  const [error, setError] = useState(null);
  const localtion = useLocation();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userid');
  const toast = useToast();
  const [groupCounts, setGroupCounts] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [newInvite, setNewInvite] = useState(null);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleInviteOpenModal = () => setIsInviteModalOpen(true);
  const handleInviteCloseModal = () => setIsInviteModalOpen(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [inviteSubmit, setInviteSubmit] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const inputRef = useRef(null); 


  useEffect(() => {
    if (localtion.pathname.indexOf("groups") > 0) {
      fetchGroups();
      fetchGroupCounts();
    }
  }, [localtion.pathname]);

  const fetchGroupCounts = async () => {
    //usrgroup/all/count
    try {
      const response = await axiosInstance.get(`/usrgroup/all/count`);
      //const responseData = await response.json();
      setGroupCounts(response.data || {});
    } catch (error) {
      setError(error.message);
    }
  }

  const fetchGroups = async () => {
    // Fetch groups from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup` );
     // const responseData = await response.json();
      setGroups(response.data || []);
      //localstorage.usrgroups
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchGroupDataById = async (groupId) => {
    // Fetch group data by ID from an API endpoint
    try {
      setInviteSubmit(false);
      const response = await axiosInstance.get(`/usrgroup/${groupId}/users` );
      const responseData = response.data;
      //console.log("responseData", responseData);
      setGroupsDataById((prevData) => ({ ...prevData, [groupId]: { users: responseData || [] } }));
      console.log("Updated groupsDataById:", groupsDataById);
    } catch (error) {
      setError(error.message);
    }
  }

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    setGroupNameValid(true);
    setIsDuplicateGroupName(false);
  };

  const MAX_GROUPS = parseInt(process.env.REACT_APP_MAX_GROUPS);
  //const MAX_INVITES_PER_GROUP = process.env.REACT_APP_MAX_INVITES_PER_GROUP;

  const handleCreateGroup = async () => {
    let isValid = true;

    // Validate group name
    if (!groupName.trim() || groupName.trim().length > 50) {
      setGroupNameValid(false);
      isValid = false;
    }

    const groupExists = groups.some(group => group.name.toLowerCase() === groupName.trim().toLowerCase());
    if (groupExists) {
      setIsDuplicateGroupName(true);
      toast({
        title: 'Error',
        description: t('group-alreadyexistmessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      isValid = false;
    }

    // Perform additional validations for other fields if needed

    if (!isValid) {
      return;
    }

    try {
      if (groups.length >= MAX_GROUPS) {
        let errorMessage = t('group-limitmessage');
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const groupRequestData = selectedCompanyId
      ? {
          name: groupName,
          isPublic: true, 
          companyId: selectedCompanyId, 
        }
      : { name: groupName };


      const response = await axiosInstance.post(`/usrgroup`, groupRequestData);
      const data = response.data;
      const newGrpSet = [...groups, data];
      setGroups(newGrpSet);
      localStorage.setItem('usrgroups', JSON.stringify(newGrpSet));
      setGroupName('');
      toast({
        title: t('group-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      setError(error.message);

      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
    //  setLoading(false);
    }
  };

  const handleInviteCreateGroup  = async () => {
    let isValid = true;

    // Validate group name
    if (!groupName.trim() || groupName.trim().length > 50) {
      setGroupNameValid(false);
      isValid = false;
    }

    const groupExists = groups.some(group => group.name.toLowerCase() === groupName.trim().toLowerCase());
    if (groupExists) {
      setIsDuplicateGroupName(true);
      toast({
        title: 'Error',
        description: t('group-alreadyexistmessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      isValid = false;
    }

    // Perform additional validations for other fields if needed

    if (!isValid) {
      return;
    }

    try {
      if (groups.length >= MAX_GROUPS) {
        let errorMessage = t('group-limitmessage');
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const response = await axiosInstance.post(`/usrgroup`, { name: groupName });
      const data = response.data;
      const newGrpSet = [...groups, data];
      setGroups(newGrpSet);
      localStorage.setItem('usrgroups', JSON.stringify(newGrpSet));
      setGroupName('');
      toast({
        title: t('group-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      setError(error.message);

      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
    //  setLoading(false);
    }
  };


  const handleCreateNewGroup = () => {
    if (!groupName.trim()) {
      setGroupNameValid(false);
      return;
    }
    // Logic to handle group creation.
    handleCreateGroup(groupName);
    setShowCreateGroup(false);
    setGroupName("");
  };

  const handleDeleteGroup = async (groupId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('group-deleteconfirmmessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/usrgroup/${groupId}`);

        setGroups(groups.filter((group) => group.id !== groupId));
        toast({
          title: t('group-deletemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleExitGroup = async (groupId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('group-exitconfirmmessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, exit it!',
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      try {
       await axiosInstance.delete(`/usrgroup/${groupId}/user/${userId}`);

        setGroups(groups.filter((group) => group.id !== groupId));
        toast({
          title: t('group-exitmessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleCompareGroup = (groupId) => {
    const path = `/shortlisted?ids=${groupId}`;
    navigate(path);
  };

  const handleGroupClick = async (group, e) => {
    if (e.target.type === 'checkbox' || e.target.closest('.chakra-checkbox')) {
      return;
    }
    const groupId = group.id;

    if (selectedGroup !== groupId) {
      await fetchGroupDataById(groupId);
    }
    setSelectedGroup(selectedGroup === groupId ? null : groupId);
  };

  const handleAddUser = () => {
    setShowAddUserForm(true);
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const invites = selectedGroup && groupsDataById[selectedGroup]
  ? groupsDataById[selectedGroup].users
  : [];

  const [inviteList, setInviteList] = useState(invites);

  const handleAddNewInvite = () => {
    console.log("test");
    setInviteList([...inviteList, { email: "", status: "inviting...", isNew: true }]);
    console.log("test123",inviteList);
  };

  const handleSaveInvite = (newInvite) => {
    setInviteList((prev) => prev.map((invite) =>
      invite.isNew ? { ...newInvite, isNew: false } : invite
    ));
  };


  const fetchSuggestions = async (term) => {
    try {
      const response = await axiosInstanceES.get(`/company-suggestions?term=${term}`);
      setSuggestions(response.data);
      setShowSuggestions(true);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setGroupName(value);
    if (value.length > 2) {  
      fetchSuggestions(value);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && groupName) {
      setShowSuggestions(false);  
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setGroupName(suggestion.company_name); 
    setSelectedCompanyId(suggestion.companyid); 
    setShowSuggestions(false);
  };

  return (
       <div className="min-h-screen flex flex-col relative">
        <div className="bg-primary font-linksans pb-34">
        <div className="container px-5 py-12 flex-1 overflow-auto">
        <SignUpProgress/>
        <div className="flex-col items-center flex justify-center mt-4">
          <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-3">
          {t('groups_mgt_title')}
            </h3>
            </div>
            <div className="flex flex-col md:flex-row h-screen">
        {/* Left Side: Groups Accordion */}
        <div className="w-full md:w-1/4 bg-gray-100 p-4">
          <h2 className="text-lg font-bold mb-4">Groups</h2>
          <div className="space-y-2">
          {groups && groups.map((group) => (
              <div
              key={group.id}
              className={`flex items-center justify-between cursor-pointer p-2 rounded-md ${
                selectedGroup === group.id ? "bg-white text-gray" : "bg-gray-200"
              } ${group.name === "Personal" ? "pointer-events-none opacity-50" : ""}`} // Disable click
              onClick={(e) => group.name !== "Personal" && handleGroupClick(group, e)} // Prevent click
            >
                {/* Group Name */}
                <span className="flex-1">{group.name}</span>

                {/* Action Buttons */}
                <div className="flex items-center space-x-8">
                  {/* Compare Button */}
                  <div className="relative">
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCompareGroup(group.id);
                      }}
                    >
                      <FaExchangeAlt className="w-5 h-5" />
                    </button>
                    <div className="absolute -right-3 -top-2 flex items-center justify-center p-2 h-3 text-xs font-bold text-gray bg-[#FFAB00] rounded-full">
                      {groupCounts && groupCounts[group.id]?.property_count || 0}
                    </div>
                  </div>

                {/* Delete or Exit Button */}
                <button
                  className={`text-sm ${
                    group.user_id === userId ? "text-gray-500 hover:text-gray-700" : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    group.user_id === userId ? handleDeleteGroup(group.id) : handleExitGroup(group.id);
                  }}
                >
                  {group.user_id === userId ? (
                    <DeleteIcon className="w-5 h-5" />
                  ) : (
                    <FaSignOutAlt className="w-5 h-5" />
                  )}
                </button>

                {selectedGroup === group.id ? (
                <span className="cursor-default"><FaCaretRight  className="w-5 h-5" /></span>
                ) : (
                <span className="cursor-default">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              )}
          </div>
        </div>
      ))}
    </div>

    {showCreateGroup ? (
      <Stack direction="row" spacing={2} mt={7}>
                <FormControl isInvalid={!groupNameValid || isDuplicateGroupName} isRequired>
                  <Menu isOpen={showSuggestions} matchWidth>
                    <Input
                      bg="white"
                      color="gray.500"
                      fontSize="sm"
                      px={3}
                      py={3}
                      borderRadius="lg"
                      placeholder="Group Name"
                      value={groupName}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      style={{
                        position: 'relative', 
                        zIndex: 1, 
                      }}
                    />
                    <MenuList 
                    maxH="500px"
                    overflowY="auto" 
                    mt={10}
                    style={{
                      position: 'absolute', 
                      zIndex: 0, 
                    }}
                    >
                  <>
                  {(suggestions || []).map((suggestion) => (
                      <MenuItem key={suggestion.companyid} 
                      as={Link}
                      to={`/groupbuyers/company/${suggestion.companyid}?cn=${encodeURIComponent(suggestion.company_name)}`}
                      >
                        {suggestion.company_name}
                      </MenuItem>
                    ))}              
                    <MenuItem
                     style={{
                      borderTop: '1px solid #c4c8d8'
                     }}
                      as={Link}
                      to={`/groupbuyers/company/new?cn=${encodeURIComponent(groupName)}`}
                      mt={2}
                      textAlign="center"
                    >+ Add Company Group
                    </MenuItem>
                    
                </>
                    </MenuList>
                  </Menu>
                  <FormErrorMessage>
                    {!groupName.trim()
                      ? "Group name is required."
                      : groupName.trim().length > 50
                      ? "Group name must be 50 characters or less."
                      : isDuplicateGroupName
                      ? "Group name already exists."
                      : null}
                  </FormErrorMessage>
                </FormControl>
                <button className="py-2 px-4 bg-white text-gray float-right rounded-md border border-blue-500" onClick={handleCreateNewGroup}>
                +Create
                </button>
              </Stack>
    ) : (
    <button
    onClick={() => setShowCreateGroup(true)}
      className="mt-10 w-full bg-white text-gray px-4 py-4 rounded-md border border-blue-500"
    >
      Create a group
    </button>
    )}
        </div>


        {/* Right Side: Tabs and Table */}
        <div className="w-full md:w-3/4 p-4">
          {/* Tabs */}
          <div className="flex flex-col sm:flex-row items-center justify-between">
            {/* Tabs Section */}
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 w-full">
            <Tabs 
            align="left"
            variant="enclosed"
            color="black"
            width="100%"
            >
              <TabList
              borderBottom="1px solid #c4c8d8"
              width="100%" 
              >
                <Tab 
                border="1px solid #c4c8d8"
                borderRadius="12px 12px 0 0"
                width={250}
                bg={activeTab === 0 ? "#fff" : "#e6ebef"}
                px={4}
                py={2}
                _hover={{
                  bg: "#f5f5f5",
                }}
                _selected={{
                  bg: "#fff",
                  color: "#000",
                  borderBottom: 'none',
                }}
                onClick={() => setActiveTab("Invites")}>Invites</Tab>
                {/* <Tab
                border="1px solid #c4c8d8"
                borderRadius="12px 12px 0 0"
                width={250}
                bg={activeTab === 0 ?  "#fff" : "#e6ebef"}
                px={4}
                py={2}
                _hover={{
                  bg: "#f5f5f5",
                }}
                _selected={{
                  bg: "#fff",
                  color: "#000",
                  borderBottom: 'none',
                }}
                onClick={() => setActiveTab("Properties")}>Properties</Tab> */}
              </TabList>
            </Tabs>
            </div>

            {/* Invite New Button */}
            {/* <button
              onClick={handleAddNewInvite} className="py-2 px-4 bg-white text-gray rounded-md border border-blue-500"
            >
              Invite&nbsp;new
            </button> */}
          </div>

          {/* Content */}
          {activeTab === "Invites" && (
            <InviteTable inviteusers={invites} onSaveInvite={handleSaveInvite}  groupId={selectedGroup}/>
          )}
          {activeTab === "Properties" && <div className="flex flex-col items-center justify-center min-h-[700px] space-y-8 px-2 py-8 border border-gray-300 bg-white"><div className="text-center text-gray-600 text-md">Properties Coming Soon...</div></div>}
        </div>
        <CreateGroupModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onCreate={handleCreateGroup}
          existgroups={groups}
        />

        <InviteModal
          isOpen={isInviteModalOpen}
          onClose={handleInviteCloseModal}
          onCreate={handleInviteCreateGroup}
          groupId={selectedGroup}
        />
        </div>
        </div>
        </div>
      </div>
  );
}

export default GroupManagement;
