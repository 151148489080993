import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  HStack,
  FormErrorMessage,
  useToast,
  Checkbox
} from "@chakra-ui/react";
import {states} from '../../data/us_states.js';
import  axiosInstance,{axiosInstanceES } from "../../utils/axio-instance.js";
import { propertyTypeOptions, mlsStatusOptions } from '../../constants';
import { useTranslation } from 'react-i18next'; 
import axios from 'axios';
import ImageUploadModal from '../grid/ImageUploadModal';


const PropertyModal = ({ isOpen, onClose, onDataAdded }) => {

  const roleInfo = localStorage.getItem('roles');  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const storedUserInfo = localStorage.getItem('userid');
  const toast = useToast();
  const { t} = useTranslation();
  const [communityOptions, setCommunityOptions] = useState([]);
  const [builderOptions, setBuilderOptions] = useState([]);
  const [showCommunityDropdown, setShowCommunityDropdown] = useState(false);
  const [showBuilderDropdown, setShowBuilderDropdown] = useState(false);
  const [communityMapping, setCommunityMapping] = useState({});
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  
  const [formData, setFormData] = useState({
    communityName: "",
    builderName: "",
    uniqueBuilderDomain: "",
    modelName: "",
    property_type: "",
    price: "",
    bedrooms: "",
    bathrooms: "",
    partial_bathrooms: "",
    numberOfGarageSpaces: "",
    sqft: "",
    stories: "",
    property_description: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    group: "false",
    mlsDaysOnMarket: "",
    metroName: "",
    county: "",
    modelConstructionStatus: ""
  });
   
  const [errors, setErrors] = useState({});

  const [showAdvanced, setShowAdvanced] = useState(false);

  const toggleAdvancedFields = () => {
    setShowAdvanced(!showAdvanced);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handlePhotosClick = (id) => {
    setIsModalOpen(true); 
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const autoSuggestCommunities = async (query) => {
    if (!query.trim()) return; // Prevent unnecessary API calls

    try {
        const response = await axiosInstanceES.get(`/listcommunities?term=${query}`);
        console.log("API Response:", response.data); // Debugging
        setCommunityOptions(response.data); // Ensure API returns an array
    } catch (error) {
        console.error("Error fetching community suggestions:", error);
    }
};


   const autoSuggestBuilders = async (query) => {
    const filtered = builderOptions.filter((option) =>
        option.toLowerCase().includes(query.toLowerCase())
    );
    setBuilderOptions(filtered);
    };

    const validateForm = () => {
      let newErrors = {};
      
      // Define mandatory fields
      const requiredFields = [
        "communityName",
        "builderName",
        "uniqueBuilderDomain",
        "modelName",
        "property_type",
        "bathrooms",
        "bedrooms",
        "sqft",
        "price",
        "state",
        "zip",
        "street",
        "city"
      ];
    
      Object.entries(formData).forEach(([key, value]) => {
        if (requiredFields.includes(key)) {  // ✅ Only validate required fields
          if (
            value === "" || 
            value === null || 
            value === undefined || 
            (Array.isArray(value) && value.length === 0)
          ) {
            newErrors[key] = "This field is required";
            console.warn(`Validation failed for: ${key}`);
          }
        }
      });
    
      console.log("Final Validation Errors:", newErrors);
      setErrors(newErrors);
      
      return Object.keys(newErrors).length === 0;
    };

  const fetchLatLong = async (address) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
      const data = response.data;

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("enterif",  data.results[0].geometry.location);
        return { lat: Number(lat), lng: Number(lng) };
      } else {
        console.warn("No results found for the address:", address);
        return { lat: null, lng: null };  // Return null if no results are found
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
      return { lat: null, lng: null };
    }
  };

   const savetransformData = async (row) => {
      let lat = null;
      let lng = null;
      const fullAddress = `${row.street|| ''}, ${row.city || ''}, ${row.state || ''}, ${row.zip || ''}`;
      if (fullAddress !== ", , , ") {
        try {
          // Wait for the fetchLatLong function to resolve
          const locationData = await fetchLatLong(fullAddress);
          lat = locationData?.lat;
          lng = locationData?.lng;
        } catch (error) {
          console.error("Error fetching lat/lng:", error);
        }
     
      }
      return {
        location:lat && lng 
        ? `${lat},${lng}` 
        : null, 
        main: {
          sourceId: row.builderName || '',
          communityPublicID: row.communityPublicID,
          propertyStatus: "active",
          recordCreatedDate: new Date().toISOString(),
          createdRole: roleInfo,
          createdByUser: storedUserInfo,
          recordType: 'property',
        },
        data: {
          propertyInfo: {
            address: {
              label: fullAddress,
              street: row.street,
              city: row.city,
              state: row.state,
              zip: row.zip,
              county: row.county,
              metroName: row.metroName
            },
            bedrooms: parseInt(row.bedrooms) || null,
            bathrooms: parseInt(row.bathrooms) || null,
            buildingSquareFeet: parseInt(row.sqft) || null,
            roomsCount: parseInt(row.totalrooms) || null,
            livingSquareFeet: row.sqft || '', 
            stories: parseInt(row.stories) || null,
            partialBathrooms: parseInt(row.partial_bathrooms) || null,
            parkingSpaces: parseInt(row.parking) || null,
            pool: row.pool,
          },
          mlsListingPrice: parseInt(row.price) || null,
          lotInfo: {
            lotSquareFeet: parseInt(row.lot_area) || null,
            legalDescription: row.property_description || null
          },
          propertyType: row.property_type,
          mlsDaysOnMarket: row.mlsDaysOnMarket,
          group : row.group || false
        },
         extraData: {
          modelName: row.modelName || null,
          modelConstructionStatus: row.modelConstructionStatus || null,
          numberOfGarageSpaces: row.numberOfGarageSpaces || null
        },
        builder : {
          builderName: row.builderName || '',
          uniqueBuilderDomain: row.uniqueBuilderDomain || ''
        },
        community: {
          communityId:  row.communityId || null,
          communityName: row.communityName || ''
        }
      };
    
    };

    const handleClose = async () => {
      setFormData({
        communityName: "",
        builderName: "",
        uniqueBuilderDomain: "",
        modelName: "",
        property_type: "",
        price: "",
        bedrooms: "",
        bathrooms: "",
        partial_bathrooms: "",
        numberOfGarageSpaces: "",
        sqft: "",
        stories: "",
        property_description: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        group: "false",
        mlsDaysOnMarket: "",
        metroName: "",
        county: "",
        modelConstructionStatus: "",
        parking: "",
        pool: "",
      });

       onClose();
    };

  const handleSaveSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) return; 

    try {

    const transformedData =   await savetransformData(formData);
   
    const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
    const elasticsearchApiUrl = `/addproperty`;

    // Save to Elasticsearch
    const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);

     // Prepare payload with user information and properties
     const payload = {
      role : roleInfo,
      properties: [ elasticsearchResponse.data.id] 
    };


    // Save to DynamoDB
    const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);


    // Check if both requests were successful
    if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
      const newPropertyId = elasticsearchResponse.data.id; 
      setSelectedPropertyId(newPropertyId); 
      //console.log
      setFormData({
        communityName: "",
        builderName: "",
        uniqueBuilderDomain: "",
        modelName: "",
        property_type: "",
        price: "",
        bedrooms: "",
        bathrooms: "",
        partial_bathrooms: "",
        numberOfGarageSpaces: "",
        sqft: "",
        stories: "",
        property_description: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        group: "false",
        mlsDaysOnMarket: "",
        metroName: "",
        county: "",
        modelConstructionStatus: "",
        parking: "",
        pool: "",
      });
    
      onDataAdded();
      handlePhotosClick(selectedPropertyId);

      toast({
        title: t('property-addedmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      throw new Error('One of the requests failed');
    }
  } catch (error) {
    console.error('Error saving new row:', error);
    toast({
      title: 'Error',
      description : "There was an error adding the property.",
      status: 'error',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
  <ModalOverlay />
  <ModalContent className="h-full max-h-[90vh]">
    <ModalHeader  className="sticky top-0 bg-white z-10 shadow-md">Add Property</ModalHeader>
    <ModalCloseButton style={{ backgroundColor: "white", zIndex: 50 }} />
    <ModalBody className="overflow-y-auto p-4">
      <VStack spacing={4} align="stretch">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <FormControl isInvalid={errors.communityName}>
              <FormLabel>Community Name <span className="text-red-500">*</span></FormLabel>
              <div style={{ position: "relative" }}>
                <Input
                  name="communityName"
                  value={formData.communityName}
                  onChange={(e) => {
                    handleChange(e);
                    autoSuggestCommunities(e.target.value);
                    setShowCommunityDropdown(true);
                  }}
                  placeholder="Search Community"
                  onFocus={() => setShowCommunityDropdown(true)}
                  onBlur={() => setShowCommunityDropdown(false)}
                />
                {showCommunityDropdown && communityOptions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      background: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {communityOptions.map((option) => (
                      <div
                        key={option.communityPublicID}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          background: "#fff",
                          borderBottom: "1px solid #eee",
                        }}
                        onMouseDown={() => {
                          setFormData((prev) => ({
                            ...prev,
                            communityName: option.communityName,
                            communityPublicID: option.communityPublicID,
                            communityId: option.id,
                            builderName: option.builderName,
                            uniqueBuilderDomain:option.uniqueBuilderDomain,
                            city: option.city,
                            state: option.state,
                            zip: option.zip,
                          }));
                          setShowCommunityDropdown(false);
                        }}
                      >
                        {option.communityName} ({option.city}, {option.state})
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <FormErrorMessage>{errors.communityName}</FormErrorMessage>
            </FormControl>

            {/* Builder Name Dropdown */}
            <FormControl isInvalid={errors.builderName}>
              <FormLabel>Builder Name <span className="text-red-500">*</span></FormLabel>
              <div style={{ position: "relative" }}>
                <Input
                  name="builderName"
                  value={formData.builderName}
                  onChange={(e) => {
                    handleChange(e);
                    autoSuggestBuilders(e.target.value);
                    setShowBuilderDropdown(true);
                  }}
                  placeholder="Search Builder"
                  onFocus={() => setShowBuilderDropdown(true)}
                  onBlur={() => setTimeout(() => setShowBuilderDropdown(false), 200)}
                />
                {showBuilderDropdown && builderOptions.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      background: "#fff",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      width: "100%",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    {builderOptions.map((option) => (
                      <div
                        key={option}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          background: "#fff",
                          borderBottom: "1px solid #eee",
                        }}
                        onClick={() => {
                          setFormData((prev) => ({
                            ...prev,
                            builderName: option,
                          }));
                          setShowBuilderDropdown(false);
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <FormErrorMessage>{errors.builderName}</FormErrorMessage>
            </FormControl>
            
            <FormControl isInvalid={errors.uniqueBuilderDomain}>
          <FormLabel>
              Build Domain
            </FormLabel>
            <Input
              name="uniqueBuilderDomain"
              placeholder="Unique Builder Domain"
              value={formData.uniqueBuilderDomain}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.uniqueBuilderDomain}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={errors.modelName}>
          <FormLabel>
            Model Name <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="modelName"
              placeholder="Model Name"
              value={formData.modelName}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.modelName}</FormErrorMessage>
          </FormControl>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <FormControl isInvalid={errors.price}>
           <FormLabel>
            Price <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="price"
              type="number"
              placeholder="Price"
              value={formData.price}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.price}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.property_type}>
          <FormLabel>
            Property Type <span className="text-red-500">*</span>
            </FormLabel>
            <Select
              name="property_type"
              placeholder="Select Property Type"
              value={formData.property_type}
              onChange={handleChange}
            >
              {propertyTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.property_type}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.bedrooms}>
        <FormLabel>
             Bedrooms <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="bedrooms"
              type="number"
              placeholder="No of Bedrooms"
              value={formData.bedrooms}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.bedrooms}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.bathrooms}>
           <FormLabel>
            Bathrooms <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="bathrooms"
               type="number"
              placeholder="No of Bathrooms"
              value={formData.bathrooms}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.bathrooms}</FormErrorMessage>
          </FormControl>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <FormControl>
          <FormLabel>
           Partial Bathrooms
            </FormLabel>
            <Input
              name="partial_bathrooms"
               type="number"
              placeholder="Partial Bathrooms"
              value={formData.partial_bathrooms}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl >
          <FormLabel>
           Garage 
            </FormLabel>
            <Input
              name="numberOfGarageSpaces"
               type="number"
              placeholder="No of GarageSpaces"
              value={formData.numberOfGarageSpaces}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl isInvalid={errors.sqft}>
          <FormLabel>
           Living SQFT <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="sqft"
               type="number"
              placeholder="Square Feet"
              value={formData.sqft}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.sqft}</FormErrorMessage>
          </FormControl>
          <FormControl>
          <FormLabel>
          No of Stories
            </FormLabel>
            <Input
              name="stories"
               type="number"
              placeholder="Stories"
              value={formData.stories}
              onChange={handleChange}
            />
          </FormControl>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <FormControl isInvalid={errors.street}>
         <FormLabel>
          Street <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="street"
              placeholder="Street"
              value={formData.street}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.street}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.city}>
          <FormLabel>
          City <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.city}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.state}>
          <FormLabel>
           State <span className="text-red-500">*</span>
            </FormLabel>
            <Select name="state" value={formData.state} onChange={handleChange}>
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>{errors.state}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.zip}>
          <FormLabel>
          Zip <span className="text-red-500">*</span>
            </FormLabel>
            <Input
              name="zip"
               type="number"
              placeholder="ZIP Code"
              value={formData.zip}
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.zip}</FormErrorMessage>
          </FormControl>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <FormControl display="flex" alignItems="center">
            <Checkbox 
              name="group" 
              isChecked={formData.group === "true"} 
              onChange={(e) => handleChange({ 
                target: { name: "group", value: e.target.checked ? "true" : "false" } 
              })}
            >
              Group-Property
            </Checkbox>
          </FormControl>

        <FormControl>
              <FormLabel>
                Description
                </FormLabel>
                <Textarea
                  name="property_description"
                  placeholder="Property Description"
                  value={formData.property_description}
                  onChange={handleChange}
                />
              </FormControl>
        </div>
          {/* Hide fields */}
          {showAdvanced && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <FormControl>
              <FormLabel>
                Days On Market
                </FormLabel>
                <Input
                  name="mlsDaysOnMarket"
                  placeholder="Day On Market"
                  value={formData.mlsDaysOnMarket}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Property Status</FormLabel>
                <Select
                  name="modelConstructionStatus"
                  value={formData.modelConstructionStatus}
                  onChange={handleChange}
                >
                  <option value="Available">Available</option>
                </Select>
              </FormControl>
              <FormControl>
              <FormLabel>
                Parking
                </FormLabel>
                <Input
                  name="parking"
                  placeholder="Parking"
                  value={formData.parking}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Pool</FormLabel>
                <Select
                  name="pool"
                  placeholder="Select option"
                  value={formData.pool}
                  onChange={handleChange}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Select>
              </FormControl>

            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <FormControl>
              <FormLabel>
                Metro Name
                </FormLabel>
                <Input
                  name="metroName"
                  placeholder="Enter Metro Name"
                  value={formData.metroName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
              <FormLabel>
                County
                </FormLabel>
                <Input
                  name="county"
                  placeholder="Enter County"
                  value={formData.county}
                  onChange={handleChange}
                />
              </FormControl>
            </div>
          </>
        )}
      </VStack>
      {isModalOpen && (
          <ImageUploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          propertyId={selectedPropertyId} // Pass the selected propertyId
          />
      )} 
    </ModalBody>

    <ModalFooter>
    <div className="flex justify-between w-full">
        {/* Left side button */}
        <button className="px-4 py-2 text-gray-700 border border-blue-600 rounded-md transition" onClick={toggleAdvancedFields}>
        {showAdvanced ? "Hide Fields" : "Advance Fields"}
        </button>

        {/* Right side buttons */}
        <div className="flex gap-3">
        <button
            className="px-4 py-2 text-gray-700 border border-blue-600 rounded-md transition"
            onClick={handleClose}
          >
            Close
          </button>
            <button className="px-4 py-2 text-gray-700 border border-blue-600 rounded-md transition" onClick={handleSaveSubmit}>
            Save & New
            </button>
        </div>
        </div>

    </ModalFooter>
  </ModalContent>
    </Modal>
  );
};

export default PropertyModal;
