import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';
import PropertyListing from '../tenstack/PropertyTable';
//import CommunityListing from './CommunityListing';
import CommunityListing from '../tenstack/CommunityTable';
import { useTranslation } from 'react-i18next'; 
import SignUpProgress from '../user/SignUpProgress';
import axiosInstance from '../../utils/axio-instance';
import { useNavigate } from "react-router-dom";

const MainListing = () => {

  const { t} = useTranslation();
  
  const kcUid= localStorage.getItem("kc_uid");
  const handleSkip = async () => {
    try {
      const user_flags = JSON.parse(localStorage.getItem("user_flags"));
      if(user_flags && user_flags.firstUpdate<5){
        const response = await axiosInstance.get(
          `/users/skip-invite-emails?kc_uid=${kcUid}&updateType=DONE`
        ); 
        if (response.status === 200) {
          const updatedFlags = { firstUpdate: 5 };
          localStorage.setItem("user_flags", JSON.stringify(updatedFlags));
        
        } else {
          console.error("Failed to skip invite emails");
        }
      }
    } catch (error) {
      console.error("Error skipping invites:", error);
    }
  };

  return (
    <div className="bg-primary font-linksans min-h-screen">
      <SignUpProgress />
      <div className="w-full px-5 py-12">
        <Box>
          <Tabs variant="enclosed" style={{borderBottom:"1px solid #c4c8d8"}}>
            <TabList >
              <Tab style={{borderRadius:"8px 8px 0 0"}} 
               width={250}        
                _selected={{
                  color: "black",                  
                  fontWeight: "bold",
                  fontSize: "lg", 
                  borderRadius:"8px 8px 0 0",                 
                  backgroundColor: "#e6ebef",
                  border:"1px solid #c4c8d8",
                  borderBottomWidth: "0px"
                }}
              >
                Communities
              </Tab>
              <Tab style={{borderRadius:"8px 8px 0 0"}}   
              width={250}     
                _selected={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "lg",                    
                  backgroundColor: "#e6ebef",
                  border:"1px solid #c4c8d8",
                  borderBottomWidth: "0px"
                }}
              >
                Properties
              </Tab>
            </TabList>
            <TabPanels className="mt-4">
              <TabPanel>
                <CommunityListing handleSkip={handleSkip}/>
              </TabPanel>
              <TabPanel>
                <PropertyListing handleSkip={handleSkip} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </div>
    </div>
  );
};

export default MainListing;
