import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormErrorMessage,
  Input,
  Button,
  useToast,
  VStack,
  Box,
  HStack,
  Text
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utils/axio-instance';


const CreateGroupModal = ({ isOpen, onClose, onCreate, existgroups }) => {
    
  const [groups, setGroups] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [groupNameValid, setGroupNameValid] = useState(true);
  const [isDuplicateGroupName, setIsDuplicateGroupName] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
   const [error, setError] = useState(null);
   
  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
    setGroupNameValid(!!e.target.value.trim() && e.target.value.trim().length <= 50);
    setIsDuplicateGroupName(false); // Reset duplication error on change
  };

   const handleJoinGroup = (e) => {

   };

  const MAX_GROUPS = parseInt(process.env.REACT_APP_MAX_GROUPS);
  //const MAX_INVITES_PER_GROUP = process.env.REACT_APP_MAX_INVITES_PER_GROUP;

  

  const fetchGroups = async () => {
    // Fetch groups from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup` );
     // const responseData = await response.json();
      setGroups(response.data || []);
      //localstorage.usrgroups
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateGroup = async () => {
    let isValid = true;

    // Validate group name
    if (!groupName.trim() || groupName.trim().length > 50) {
      setGroupNameValid(false);
      isValid = false;
    }

    const groupExists = existgroups.some(group => group.name.toLowerCase() === groupName.trim().toLowerCase());
    console.log(existgroups);
    if (groupExists) {
      setIsDuplicateGroupName(true);
      toast({
        title: 'Error',
        description: t('group-alreadyexistmessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      isValid = false;
    }

    // Perform additional validations for other fields if needed

    if (!isValid) {
      return;
    }

    try {
      if (groups.length >= MAX_GROUPS) {
        let errorMessage = t('group-limitmessage');
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const response = await axiosInstance.post(`/usrgroup`, { name: groupName });
      const data = response.data;
      const newGrpSet = [...groups, data];
      setGroups(newGrpSet);
      localStorage.setItem('usrgroups', JSON.stringify(newGrpSet));
      setGroupName('');
      toast({
        title: t('group-successmessage'),
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      onClose();
      fetchGroups();
    } catch (error) {
      setError(error.message);

      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
    //  setLoading(false);
    }
  };

  const handleCreate = () => {
    if (!groupName.trim()) {
      setGroupNameValid(false);
      return;
    }

    if (groupName.trim().length > 50) {
      return;
    }

    // Simulate duplication check (replace with real logic if needed)
    if (false /* check for duplicates here */) {
      setIsDuplicateGroupName(true);
      return;
    }

    onCreate({ id: Date.now(), name: groupName.trim() });
    setGroupName('');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Join Group OR Create Your Own</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        <HStack spacing={6} justify="center">
            {/* Join Existing Group */}
            <Box
              bg="gray.200"
              p={6}
              borderRadius="lg"
              textAlign="center"
              w="50%"
            >
              <Text fontWeight="bold" fontSize="lg">Amazon</Text>
              <Text fontSize="sm" color="gray.600">There are already 200+ joined</Text>
              <Button
                mt={4}
                onClick={handleJoinGroup}
              >
                Join Now
              </Button>
            </Box>

            {/* Create a New Group */}
            <Box
              bg="gray.200"
              p={6}
              borderRadius="lg"
              textAlign="center"
              w="50%"
            >
              <Text fontWeight="bold" fontSize="lg">Create your own group here</Text>
              <FormControl mt={3} isInvalid={!groupNameValid || isDuplicateGroupName} isRequired>
                <Input
                  placeholder="Enter Group name"
                  bg="white"
                  value={groupName}
                  onChange={handleGroupNameChange}
                />
                <FormErrorMessage>
                  {!groupName.trim()
                    ? 'Group name is required.'
                    : groupName.trim().length > 50
                    ? 'Group name must be 50 characters or less.'
                    : isDuplicateGroupName
                    ? 'Group name already exists.'
                    : null}
                </FormErrorMessage>
              </FormControl>
              <Button
                mt={4}
                onClick={handleCreateGroup}
              >
                Create Group
              </Button>
            </Box>
          </HStack>
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button variant="outline" onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateGroupModal;
