import React, { useState, useEffect } from 'react';
import {  useToast  } from '@chakra-ui/react'; // Import Chakra UI components
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import { useAuth } from './AuthProvider';
import SignUpProgress from './SignUpProgress.js';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axiosInstance from '../../utils/axio-instance.js';



const BuilderInfo = () => {
  const userId = localStorage.getItem('userid');
  const [builderInfo, setBuilderInfo] = useState({
    builderName: '',
    builderHQEmail: '',
    builderHQPhone: '',
    builderAddress: '',
    companyWebsite: '',
    latitude: '',
    longitude: '',
    zipcode:'',
    message:''
  });

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const toast = useToast();
  const { t} = useTranslation();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const {globalMsg, setGlobalMsg} = useAuth();
  const [builderAddress, setBuilderAddress] = useState("");
  const [phone, setPhone] = useState(builderInfo?.phone || "");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(()=> {
    getBuilderInfo();
  },[]);

  
  useEffect(() => {
    if (builderInfo?.phone) {
      setPhone(builderInfo.phone);
    }
  }, [builderInfo]);

  useEffect(() => {
    if (location.pathname.indexOf("builder_info") > 0) {
      getBuilderInfo();
    }
  }, [location.pathname]);

  const validateFields = () => {
    const newErrors = {};
    if (!builderInfo.builderName?.trim() && !builderInfo.company?.trim()) {
      newErrors.builderName = "Builder Name is required.";
    }

    if (!builderInfo.phone ) {
       newErrors.phone  = "Phone is required.";
    }

    if(builderInfo.builderHQEmail && !validateEmail(builderInfo.builderHQEmail)){
      newErrors.builderHQEmail = "Invalid Email";
    }
    
    if (!builderInfo.builderHQPhone || !validatePhone(builderInfo.builderHQPhone)) {
      newErrors.builderHQPhone = "Invalid phone format. Please use +[country code][number].";
    }
  
    if (!builderInfo.builderAddress) {
      newErrors.builderAddress = "Builder Address required.";
  }

    if (builderInfo.companyWebsite && !validateWebsite(builderInfo.companyWebsite)) {
      newErrors.companyWebsite = "Invalid company website URL.";
    }
    return newErrors;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d{1,3}\d{1,14}$/; // Simple phone validation regex
    return phoneRegex.test(phone);
  };

  const validateWebsite = (website) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(website);
  };

  const getBuilderInfo = () => {
    axiosInstance.get(`/users/${userId}/builder-info/`)
      .then(response => {
        setBuilderInfo({ ...response.data });
      })
      .catch(error => {
        console.error('Error in loading getBuilderInfo:', error);
      });
  };


  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
  
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("builderAddress"), {
        types: ["(cities)"], // Restrict to cities only
        componentRestrictions: { country: "us" },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
  
        // Extract city and state
        const addressComponents = place.address_components || [];

        const zipCodeComponent = addressComponents.find((component) =>
          component.types.includes("postal_code")
        );
       
  
        // Extract latitude and longitude
        const address = place.formatted_address || "";
        const lat = place.geometry?.location?.lat();
        const lng = place.geometry?.location?.lng();
        const zip = zipCodeComponent?.long_name || "";
  
        // Update address field with formatted address
        setBuilderInfo((prev) => ({
          ...prev,
          builderAddress: address,
          latitude: lat,
          longitude: lng,
          zipcode:zip
        }));
      });
    };
  }, []);

  const handleSuggestionClick = (description) => {
    setBuilderAddress(description);
    setSuggestions([]);
  };


    
  const handlePhoneChange = (value) => {
    setPhone(value);
    setBuilderInfo((prev) => ({
      ...prev,
      phone: value,
    }));
  };
  


  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    setSuccessMessage('');
    setErrorMessage('');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set the validation errors
      return;
    }
    const kcuserid = localStorage.getItem("kc_uid");
    const builderInfoFinal = {...builderInfo, kc_uid: kcuserid , canWeSms: true };
    axiosInstance.put(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, builderInfoFinal)
      .then(response => {
        setSuccessMessage(t('saved'));
        setGlobalMsg('');
       // const updatedFlags = { firstUpdate: 2 };
        //localStorage.setItem("user_flags", JSON.stringify(updatedFlags));
        navigate('/builder/properties/');
        toast({
          title: t('builderinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setErrorMessage(t('failed'));
        toast({
          title: 'Error',
          description: t('builderinfo-failedmessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update the builderInfo state
    setBuilderInfo({ ...builderInfo, [name]: value });
  
    // Clear specific field errors when the input is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validate each field when input changes and remove the error if it's valid
      if (name === 'builderName' && value.trim()) {
        delete newErrors.builderName;
      } else if (name === 'builderHQEmail' && validateEmail(value)) {
        delete newErrors.builderHQEmail;
      } else if (name === 'builderHQPhone' && validatePhone(value)) {
        delete newErrors.builderHQPhone;
      } else if (name === 'builderAddress' && value) {
        delete newErrors.builderAddress;
      } else if (name === 'companyWebsite' && validateWebsite(value)) {
        delete newErrors.companyWebsite;
      } else if (name === 'jobTitle') {
        delete newErrors.jobTitle;
      } else if (name === 'approximateDeadline') {
        delete newErrors.approximateDeadline;
      }
  
      return newErrors;
    });
  };
  

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer); // Cleanup the timeout
    }
  }, [successMessage, errorMessage]);

  return (
    <div className="bg-primary font-linksans min-h-screen">
      <SignUpProgress/>
  <div className="container px-5 min-h-svh">
    <div className="flex flex-col items-center justify-center py-12">
    <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
          Builder Information
        </h3>
        <div className="top-sec w-full  flex justify-center pb-10 w-[600px] max-w-xl flex flex-col">
      
        {/* Responsive grid layout */}
     
         <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {/* First Column */}
          <div className="w-full">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder Name <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderName"
                  placeholder="Enter Builder Name"
                  value={builderInfo.builderName ?? builderInfo.company ?? ""}
                  onChange={handleInputChange}
                />
                {errors.builderName && (
                  <span className="text-sm text-red-500">{errors.builderName}</span>
                )}
              </div>

              <div className="mb-4">
                  <label className="block font-medium">
                      Mobile <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      id="phone"
                      placeholder="Enter Phone Number"
                      className="text-gray-500 text-sm px-3 py-2 rounded-lg bg-white border w-full"
                      value={phone}
                      onChange={handlePhoneChange}
                      defaultCountry="US"
                      international
                      withCountryCallingCode={false} 
                    />
                     {errors.phone && (
                      <span className="text-sm text-red-500">{errors.phone}</span>
                    )}
                  </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder HQ Email</label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderHQEmail"
                  type="email"
                  placeholder="Enter Builder HQ Email"
                  value={builderInfo.builderHQEmail ?? builderInfo.email ?? ""}
                  onChange={handleInputChange}
                />
                {errors.builderHQEmail && (
                  <span className="text-sm text-red-500">{errors.builderHQEmail}</span>
                )}
              </div>
              <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Builder Address <span className="text-red-500">*</span>
              </label>
              <input
                className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                id="builderAddress"
                name="builderAddress"
                placeholder="Enter Builder Address"
                value={builderInfo.builderAddress}
                onChange={handleInputChange}
              />
              {loading && <p>Loading suggestions...</p>}
              {suggestions.length > 0 && (
                <ul className="suggestions-list">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleSuggestionClick(suggestion.description)}
                    >
                      {suggestion.description}
                    </li>
                  ))}
                </ul>
              )}
              {errors.builderAddress && (
                <span className="text-sm text-red-500">{errors.builderAddress}</span>
              )}
            </div>
            <input type="hidden" name="latitude" value={builderInfo.latitude}/>
            <input type="hidden" name="longitude" value={builderInfo.longitude}/>
            <input type="hidden" name="zipcode" value={builderInfo.zipcode}/>
            </form>
          </div>

          {/* Second Column */}
          <div className="w-full">
          <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Builder HQ Phone<span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                  name="builderHQPhone"
                  type="tel"
                  placeholder="Enter Builder HQ Phone"
                  value={builderInfo.builderHQPhone ?? builderInfo.phone ?? ""}
                  onChange={handleInputChange}
                />
                {errors.builderHQPhone && (
                  <span className="text-sm text-red-500">{errors.builderHQPhone}</span>
                )}
              </div>
              {/* canwesms Column */}
              {/* <div className="mb-4 flex items-center space-x-3">
             <label className="block font-medium mb-9">&nbsp;</label>
            <label className="text-sm text-paragraphColor" htmlFor="can-we-sms">
              Can We Send SMS?
            </label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={canWeSms === 'yes'}
                onChange={(e) => {
                  const value = e.target.checked ? 'yes' : 'no';
                   setCanWeSms(value);
                  setBuilderInfo((prev) => ({ ...prev, canWeSms: value }));
                }}
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div> */}

          <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Approximate Deadline
              </label>
              <input
                className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                name="approximateDeadline"
                placeholder="Dead Line in Months e.g: 2Months"
                value={builderInfo.approximateDeadline }
                onChange={handleInputChange}
              />
              {errors.approximateDeadline  && (
                <span className="text-sm text-red-500">{errors.approximateDeadline }</span>
              )}
            </div>

           

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Company Website 
              </label>
              <input
                className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                name="companyWebsite"
                placeholder="Enter Website URL"
                value={builderInfo.companyWebsite}
                onChange={handleInputChange}
              />
              {errors.companyWebsite && (
                <span className="text-sm text-red-500">{errors.companyWebsite}</span>
              )}
            </div>
          </div>

          </div>
          <div className="w-full ">
            <label className="text-sm text-paragraphColor" htmlFor="message">Message</label>
            <textarea 
              id="message" 
              name="message"
              className="w-full text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
              placeholder="Enter your message here..."
              value={builderInfo.message}
              onChange={handleInputChange}
            />
            {errors.message && (
              <span className="text-sm text-red-400">{errors.message}</span>
            )}
         
          </div>

        {/* Submit Button */}
        <div className="w-full md:w-[500px] flex justify-center mt-4">
          <button
            type="submit"
            className="w-full max-w-xs text-sm py-3 rounded-lg font-semibold text-gray-700 bg-white border border-blue-500 hover:bg-gray-100 focus:ring focus:ring-blue-500"
            onClick={handleSubmit}
          >
            Update Builder Info
          </button>
        </div>

       
      </div>
    </div>
  </div>
</div>
  );
};

export default BuilderInfo;
