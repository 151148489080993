import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormErrorMessage,
  VStack,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import axiosInstance from '../../utils/axio-instance';

const InviteModal = ({ isOpen, onClose, groupId }) => {
    console.log("groupId", groupId);
  const [emails, setEmails] = useState(["", "", "", "", ""]);
  const [errors, setErrors] = useState([false, false, false, false, false]);
  const toast = useToast();
  
  const [groupsDataById, setGroupsDataById] = useState([]);
  const [error, setError] = useState(null);
  // Handle email input change
  const handleChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);

    // Validate on input change
    validateEmails(updatedEmails);
  };

  // Email validation function
  const validateEmails = (emailList) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newErrors = emailList.map((email) => (email && !emailRegex.test(email)));
    setErrors(newErrors);
    return newErrors.every((error) => !error);
  };

  const fetchGroupDataById = async (groupId) => {
    // Fetch group data by ID from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup/${groupId}/users` );
      const responseData = response.data;
      //console.log("responseData", responseData);
      setGroupsDataById((prevData) => ({ ...prevData, [groupId]: { users: responseData || [] } }));
    } catch (error) {
      setError(error.message);
    }
  }

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateEmails(emails) || emails.every((email) => email.trim() === "")) {
      toast({
        title: "Invalid Input",
        description: "Please enter at least one valid email.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    try {
        const inviteData = {
            invites: emails
              .filter((email) => email.trim() !== ""), // Remove empty fields
          };

      const response = await axiosInstance.post(`/usrgroup/${groupId}/invite_batch`, inviteData);

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Invites sent successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setEmails(["", "", "", "", ""]); // Reset inputs after successful submission
        fetchGroupDataById(groupId)
        onClose();
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to send invites",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite Your Friends</ModalHeader>
        <ModalBody>
          <VStack spacing={3}>
            {emails.map((email, index) => (
              <FormControl key={index} isInvalid={errors[index]}>
                <Input
                  type="email"
                  placeholder="Enter Email e.g. john@gmail.com"
                  value={email}
                  onChange={(e) => handleChange(index, e.target.value)}
                  bg="white"
                />
                {errors[index] && <FormErrorMessage>Invalid email format</FormErrorMessage>}
              </FormControl>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
            Submit
          </Button>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteModal;
