import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams
import PhoneInput from "react-phone-number-input";
import { useToast} from '@chakra-ui/react'; 
import { useTranslation } from 'react-i18next'; 
import "react-phone-number-input/style.css";
import axiosInstance from '../../utils/axio-instance.js';
import ProgressBarWithoutSignin from './ProgressBarWithoutSignin.js';

const BuilderInformationForm = () => {
    const { userId, kcUid } = useParams(); 
    const [builderInfo, setBuilderInfo] = useState({
    builderName: "",
    phone: "",
    builderHQEmail: "",
    builderAddress: "",
    builderHQPhone: "",
    approximateDeadline: "",
    companyWebsite: "",
    message: "",
  });

  const [phone, setPhone] = useState(builderInfo?.phone || "");
  const [suggestions, setSuggestions] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [builderAddress, setBuilderAddress] = useState("");
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const { t} = useTranslation();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Update the builderInfo state
    setBuilderInfo({ ...builderInfo, [name]: value });
  
    // Clear specific field errors when the input is valid
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validate each field when input changes and remove the error if it's valid
      if (name === 'builderName' && value.trim()) {
        delete newErrors.builderName;
      } else if (name === 'builderHQEmail' && validateEmail(value)) {
        delete newErrors.builderHQEmail;
      } else if (name === 'builderHQPhone' && validatePhone(value)) {
        delete newErrors.builderHQPhone;
      } else if (name === 'builderAddress' && value) {
        delete newErrors.builderAddress;
      } else if (name === 'companyWebsite' && validateWebsite(value)) {
        delete newErrors.companyWebsite;
      } else if (name === 'jobTitle') {
        delete newErrors.jobTitle;
      } else if (name === 'approximateDeadline') {
        delete newErrors.approximateDeadline;
      }
  
      return newErrors;
    });
  };
  
  const validateFields = () => {
    const newErrors = {};
    if (!builderInfo.builderName?.trim() && !builderInfo.company?.trim()) {
      newErrors.builderName = "Builder Name is required.";
    }

    if (!builderInfo.phone ) {
       newErrors.phone  = "Phone is required.";
    }

    if(builderInfo.builderHQEmail && !validateEmail(builderInfo.builderHQEmail)){
      newErrors.builderHQEmail = "Invalid Email";
    }
    
    if (!builderInfo.builderHQPhone || !validatePhone(builderInfo.builderHQPhone)) {
      newErrors.builderHQPhone = "Invalid phone format. Please use +[country code][number].";
    }
  
    if (!builderInfo.builderAddress) {
      newErrors.builderAddress = "Builder Address required.";
  }

    if (builderInfo.companyWebsite && !validateWebsite(builderInfo.companyWebsite)) {
      newErrors.companyWebsite = "Invalid company website URL.";
    }
    return newErrors;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\+\d{1,3}\d{1,14}$/; // Simple phone validation regex
    return phoneRegex.test(phone);
  };

  const validateWebsite = (website) => {
    const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return regex.test(website);
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setBuilderInfo((prev) => ({
      ...prev,
      phone: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateFields();
    setSuccessMessage('');
    setErrorMessage('');
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set the validation errors
      return;
    }
    
    const builderInfoFinal = {...builderInfo, kc_uid: kcUid , canWeSms: true };
    axiosInstance.put(`${process.env.REACT_APP_DYNAMO_API_URL}/users/${userId}/builder-info/`, builderInfoFinal)
      .then(response => {
        setSuccessMessage(t('saved'));
        navigate('/success/', { state: { role: "builder", step: 3 } });
        toast({
          title: t('builderinfo-updatemessage'),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      })
      .catch(error => {
        console.error('Error updating profile:', error);
        setErrorMessage(t('failed'));
        toast({
          title: 'Error',
          description: t('builderinfo-failedmessage'),
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

  useEffect(() => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initAutocomplete&libraries=places&v=weekly`;
      script.defer = true;
      document.head.appendChild(script);
  
      window.initAutocomplete = () => {
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("builderAddress"), {
          types: ["(cities)"], // Restrict to cities only
          componentRestrictions: { country: "us" },
        });
  
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
    
          // Extract city and state
          const addressComponents = place.address_components || [];
  
          const zipCodeComponent = addressComponents.find((component) =>
            component.types.includes("postal_code")
          );
         
    
          // Extract latitude and longitude
          const address = place.formatted_address || "";
          const lat = place.geometry?.location?.lat();
          const lng = place.geometry?.location?.lng();
          const zip = zipCodeComponent?.long_name || "";
    
          // Update address field with formatted address
          setBuilderInfo((prev) => ({
            ...prev,
            builderAddress: address,
            latitude: lat,
            longitude: lng,
            zipcode:zip
          }));
        });
      };
    }, []);

    const handleSuggestionClick = (description) => {
        setBuilderAddress(description);
        setSuggestions([]);
      };
  

  return (
    <div className="bg-primary font-linksans min-h-screen">
        <ProgressBarWithoutSignin step={2}/>
        <div className="container px-5 min-h-svh">
        <div className="flex flex-col items-center justify-center py-12">
        <h3 className="2xl:text-4xl xl:text-4xl lg:text-3xl md:Text-2xl text-2xl font-bold text-textColor pb-10">
                Builder Information
            </h3>
            <div className="top-sec w-full  flex justify-center pb-10 w-[600px] max-w-xl flex flex-col">
            
            {/* Responsive grid layout */}
        
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
                {/* First Column */}
                <div className="w-full">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Builder Name <span className="text-red-500">*</span>
                    </label>
                    <input
                        className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                        name="builderName"
                        placeholder="Enter Builder Name"
                        value={builderInfo.builderName ?? builderInfo.company ?? ""}
                        onChange={handleInputChange}
                    />
                    {errors.builderName && (
                        <span className="text-sm text-red-500">{errors.builderName}</span>
                    )}
                    </div>

                    <div className="mb-4">
                        <label className="block font-medium">
                            Mobile <span className="text-red-500">*</span>
                        </label>
                        <PhoneInput
                            id="phone"
                            placeholder="Enter Phone Number"
                            className="text-gray-500 text-sm px-3 py-2 rounded-lg bg-white border w-full"
                            value={phone}
                            onChange={handlePhoneChange}
                            defaultCountry="US"
                            international
                            withCountryCallingCode={false} 
                        />
                        {errors.phone && (
                            <span className="text-sm text-red-500">{errors.phone}</span>
                        )}
                        </div>

                    <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Builder HQ Email</label>
                    <input
                        className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                        name="builderHQEmail"
                        type="email"
                        placeholder="Enter Builder HQ Email"
                        value={builderInfo.builderHQEmail ?? builderInfo.email ?? ""}
                        onChange={handleInputChange}
                    />
                    {errors.builderHQEmail && (
                        <span className="text-sm text-red-500">{errors.builderHQEmail}</span>
                    )}
                    </div>
                    <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                    Builder Address <span className="text-red-500">*</span>
                    </label>
                    <input
                    className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                    id="builderAddress"
                    name="builderAddress"
                    placeholder="Enter Builder Address"
                    value={builderInfo.builderAddress}
                    onChange={handleInputChange}
                    />
                    {loading && <p>Loading suggestions...</p>}
                    {suggestions.length > 0 && (
                    <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(suggestion.description)}
                        >
                            {suggestion.description}
                        </li>
                        ))}
                    </ul>
                    )}
                    {errors.builderAddress && (
                    <span className="text-sm text-red-500">{errors.builderAddress}</span>
                    )}
                </div>
                <input type="hidden" name="latitude" value={builderInfo.latitude}/>
                <input type="hidden" name="longitude" value={builderInfo.longitude}/>
                <input type="hidden" name="zipcode" value={builderInfo.zipcode}/>
                </form>
                </div>

                {/* Second Column */}
                <div className="w-full">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                        Builder HQ Phone<span className="text-red-500">*</span>
                    </label>
                    <input
                        className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                        name="builderHQPhone"
                        type="tel"
                        placeholder="Enter Builder HQ Phone"
                        value={builderInfo.builderHQPhone ?? builderInfo.phone ?? ""}
                        onChange={handleInputChange}
                    />
                    {errors.builderHQPhone && (
                        <span className="text-sm text-red-500">{errors.builderHQPhone}</span>
                    )}
                    </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                    Approximate Deadline
                    </label>
                    <input
                    className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                    name="approximateDeadline"
                    placeholder="Dead Line in Months e.g: 2Months"
                    value={builderInfo.approximateDeadline }
                    onChange={handleInputChange}
                    />
                    {errors.approximateDeadline  && (
                    <span className="text-sm text-red-500">{errors.approximateDeadline }</span>
                    )}
                </div>

                

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                    Company Website 
                    </label>
                    <input
                    className="w-full bg-white text-gray-500 text-sm px-3 py-3 border rounded-lg focus:ring focus:ring-blue-500"
                    name="companyWebsite"
                    placeholder="Enter Website URL"
                    value={builderInfo.companyWebsite}
                    onChange={handleInputChange}
                    />
                    {errors.companyWebsite && (
                    <span className="text-sm text-red-500">{errors.companyWebsite}</span>
                    )}
                </div>
                </div>

                </div>
                <div className="w-full ">
            
                <label className="text-sm text-paragraphColor" htmlFor="message">Message</label>
                <textarea 
                    id="message" 
                    name="message"
                    className="w-full text-gray-500 text-sm px-3 py-3 rounded-lg bg-white" 
                    placeholder="Enter your message here..."
                    value={builderInfo.message}
                    onChange={handleInputChange}
                />
                {errors.message && (
                    <span className="text-sm text-red-400">{errors.message}</span>
                )}
            
                </div>

            {/* Submit Button */}
            <div className="w-full md:w-[500px] flex justify-center mt-4">
                <button
                type="submit"
                className="w-full max-w-xs text-sm py-3 rounded-lg font-semibold text-gray-700 bg-white border border-blue-500 hover:bg-gray-100 focus:ring focus:ring-blue-500"
                onClick={handleSubmit}
                >
                Submit
                </button>
            </div>

            
            </div>
        </div>
        </div>
    </div>
  );
};

export default BuilderInformationForm;
