import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  CloseButton,
  useToast
} from '@chakra-ui/react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { useAuth } from './AuthProvider';
import { useTranslation } from 'react-i18next'; 
import ProgressBarWithoutSignin from './ProgressBarWithoutSignin'

export default function SignupCard() {

  const [userPreferences, setUserPreferences] = useState({ firstName: '', lastName: '', email: '', role:'' });

  useEffect(() => {
    // Retrieve stored preferences from sessionStorage
    const storedPreferences = sessionStorage.getItem('userPreferences');
    if (storedPreferences) {
      setUserPreferences(JSON.parse(storedPreferences));
    }
  }, []);

  const { t} = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    roles: ['buyer'],
    password: '',
    rpassword: '',
    termsOfUse: false,
    legalDocuments: true,
    group_id: '',
  });

  useEffect(() => {
    if (userPreferences) {
      setFormData((prevData) => ({
        ...prevData,
        firstName: userPreferences.firstName || '',
        lastName: userPreferences.lastName || '',
        email: userPreferences.email || '',
        roles: userPreferences.role ? [userPreferences.role] : ['buyer'],
      }));
    }
  }, [userPreferences]);

  const [captchaToken, setCaptchaToken] = useState('');
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const successRef = useRef(null);
  const errorRef = useRef(null);
  const toast = useToast();
  const { checkLogIn, setUser } = useAuth();

  //const hideBuyer = location.pathname.includes("/sign-up");
  const hideAgent = location.pathname.includes("/agent/sign-up/");
  const hideBuilder = location.pathname.includes("/builder/sign-up/");
  const hideBuyer = hideAgent || hideBuilder;

  let byText = "Buyer"; // Default
  if (location.pathname.includes("/agent/sign-up/")) byText = "Agent";
  if (location.pathname.includes("/builder/sign-up/")) byText = "Builder";

  useEffect(() => {
    if (token) {
      // Fetch the invitation details using the token
      axios
        .get(`${process.env.REACT_APP_DYNAMO_API_URL}/usrgroup/invitedetails/${token}`)
        .then((response) => {
          const usrDetails = response.data;
          setFormData({
            firstName: usrDetails.first_name,
            lastName: usrDetails.last_name,
            email: usrDetails.email,
            roles: ['buyer'],
            password: '',
            rpassword: '',
            termsOfUse: false,
            legalDocuments: true,
            group_id: usrDetails.group_id
          })
        })
        .catch((err) => {

          toast({
            title: 'Error',
            description: "Token Expired",
            status: 'error',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
        });
    } else {
      //no token available
    }
    if (checkLogIn()) {
      navigate('/');
    }
  }, [location, navigate])

  useEffect(() => {
    if (successMessage) {
     // successRef.current.focus();
      setTimeout(() => {
        setSuccessMessage('');
        navigate('/login');
      }, 1000);
    }
    if (errorMessage) {
      //errorRef.current.focus();
    }
  }, [successMessage, errorMessage, navigate]);


  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    // Clear corresponding error when user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
   e.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };
    let role = 'buyer'
    if(hideAgent){
      role = 'selleragent';
    }
    if(hideBuilder){
      role = 'builder';
    }
    const requestData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      roles: [role],
      password: formData.password,
      legal_acceptance: 'true'
    };

    if (formData.group_id && token) {
      requestData.group_id = formData.group_id;
    }

    // Form validation

    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
      formIsValid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
      formIsValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      formIsValid = false;
    }

    if (!formData.password.trim()) {
      newErrors.password = 'Password is required';
      formIsValid = false;
    }
    if (!formData.rpassword.trim()) {
      newErrors.rpassword = 'Repeat Password is required';
      formIsValid = false;
    }

    if (formData.password !== formData.rpassword) {
      newErrors.password = 'Passwords do not match';
      newErrors.rpassword = 'Passwords do not match';
      formIsValid = false;
    }

    if (!formData.termsOfUse) {
      newErrors.termsOfUse = 'Please accept the Terms of Use';
      formIsValid = false;
    }
    if (!formData.legalDocuments) {
      newErrors.legalDocuments = 'Please accept the Legal Documents';
      formIsValid = false;
    }

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    // Verify Google reCAPTCHA token
    if (!captchaToken) {
      toast({
        title: 'Error',
        description: t('captcha-validate'),
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      return;
    }
    console.log("before sending", requestData);
    // Send data to Node.js API
    axios.post(`${process.env.REACT_APP_DYNAMO_API_URL}/users/registration`, requestData)
      .then((response) => {
        if (response.status === 200) {
          const { user_id, kc_uid } = response.data; // Extract user_id & kc_uid
    
          setSuccessMessage(t('signup-successmessage'));
          toast({
            title: t('signup-successmessage'),
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
          });
    
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            roles: [],
            password: '',
            rpassword: '',
            termsOfUse: false,
            legalDocuments: true,
          });
          setCaptchaToken('');
          setErrors({});
          setSuccessMessage('');
    
          if (role === 'builder') {
            navigate(`/builder/info/${user_id}/${kc_uid}`);
          } else if (role === 'selleragent') {
            navigate(`/agent/info/${user_id}/${kc_uid}`);
          }
          else {
            navigate('/signup-success'); 
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
        toast({
          title: 'Error',
          description: error.response.data.error,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        console.error('Error submitting form:', error);
        setErrors({ ...errors, recaptcha: 'Error submitting form.' });
        // Handle error
      });
  };


  return (
    <div className="bg-primary font-linksans">
        <div className="container mx-auto px-12 py-12 md:px-10 sm:px-10">
          <ProgressBarWithoutSignin />
          <div className="flex flex-col md:flex-row flex-wrap justify-center gap-6 w-full">
             <div className="w-full md:w-[35%] flex flex-col justify-between">
                <h2 className="text-paragraphColor 2xl:text-xl xl:text-xl lg:text-xl justify-center md:text-lg text-base text-center font-semibold mt-6 mb-6">
                Getting started as {byText}
                </h2>
                <div>
                  {/* form */}
                  <form  action="" className="w-full space-y-3 flex flex-col">
                    <div className="flex flex-wrap md:flex-nowrap gap-4">
                      {/* First Name */}
                      <div className="flex flex-col gap-2 w-full md:w-[calc(50%-0.5rem)]">
                        <label
                          className="text-paragraphColor text-sm"
                          htmlFor="firstName"
                        >
                          First Name <span className="text-red-400">*</span>
                        </label>
                        <input
                          placeholder="First Name"
                          className="text-paragraphColor focus:outline-primary focus:border-none focus:ring-none bg-[#FAFBFC] rounded-lg p-2 text-sm 2xl:text-base"
                          type="text"
                          name="firstName"
                          id="firstName"
                          value={formData.firstName} 
                          onChange={handleInputChange}
                        />
                        {errors.firstName && (
                          <span className="text-sm text-red-400">
                            This field is required
                          </span>
                        )}
                      </div>

                      {/* Last Name */}
                      <div className="flex flex-col gap-2 w-full md:w-[calc(50%-0.5rem)]">
                        <label
                          className="text-paragraphColor text-sm"
                          htmlFor="lastName"
                        >
                          Last Name
                        </label>
                        <input
                          placeholder="Last Name"
                          className="text-paragraphColor focus:outline-primary focus:border-none focus:ring-none bg-[#FAFBFC] rounded-lg p-2 text-sm 2xl:text-base"
                          type="text"
                          name="lastName"
                          id="lastName"
                          value={formData.lastName} 
                          onChange={handleInputChange}
                        />
                        {errors.lastName && (
                          <span className="text-sm text-red-400">
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col gap-2 ">
                      <label className="text-paragraphColor text-sm" htmlFor="number">
                        Email Address
                      </label>
                      <input
                        placeholder="Email"
                        className="text-paragraphColor focus:outline-primary focus:border-none focus:ring-none bg-[#FAFBFC] rounded-lg 2xl:p-3 xl:p-3 lg:p-3 md:p-2 p-2 text-sm  2xl:text-base lg:text-base xl:text-base md:text-sm "
                        type="email"
                        name="email"
                        id="email"
                        value={formData.email} onChange={handleInputChange}
                      />
                      {errors.email && <span className="text-sm text-red-400">This field is required</span>}
                    </div>
                    <div className="flex flex-col gap-2 ">
                      <label
                        className="text-paragraphColor text-sm"
                        htmlFor="createPassword"
                      >
                        Create Password <span className="text-red-400">*</span>
                      </label>
                      <input
                        placeholder="*******"
                        className="text-paragraphColor focus:outline-primary focus:border-none focus:ring-none bg-[#FAFBFC] rounded-lg 2xl:p-3 xl:p-3 lg:p-3 md:p-2 p-2 text-sm  2xl:text-base lg:text-base xl:text-base md:text-sm "
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                      {errors.password && <span className="text-sm text-red-400">This field is required</span>}
                    </div>
                    <div className="flex flex-col gap-2 ">
                      <label
                        className="text-paragraphColor text-sm"
                        htmlFor="reEnterPassword"
                      >
                        Re-Enter Password <span className="text-red-400">*</span>
                      </label>
                      <input
                        placeholder="*******"
                        className="text-paragraphColor focus:outline-primary focus:border-none focus:ring-none bg-[#FAFBFC] rounded-lg 2xl:p-3 xl:p-3 lg:p-3 md:p-2 p-2 text-sm  2xl:text-base lg:text-base xl:text-base md:text-sm "
                        type="password"
                        name="rpassword"
                        value={formData.rpassword}
                      onChange={handleInputChange}
                      />
                      {errors.rpassword && <span className="text-sm text-red-400">This field is required</span>}
                    </div>
                    <div className="flex flex-col gap-2 ">
                      <label className="text-paragraphColor text-sm">
                        <input
                          type="checkbox"
                          name="termsOfUse"
                          checked={formData.termsOfUse}
                          onChange={handleInputChange}
                        /> I accept the <Link to="/terms/" className="hover:underline" target='_blank'>Terms & Conditons</Link>, <Link to="/Privacy/" className="hover:underline" target='_  blank'>Privacy Policy</Link> and <Link to="/disclosures/" className="hover:underline" target='_  blank'>Disclosures</Link><span className="text-red-400">*</span>
                      </label>
                      {errors.termsOfUse && <span className="text-sm text-red-400"> Please accept the Terms & Conditons, Privacy Policy and Disclosures</span>}
                    </div>
                  
                    <div className="flex justify-center">
                      <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onChange={handleCaptchaChange} />
                    </div>
                    <div className="flex justify-center w-full">
                      <button className="text-sm 2xl:text-base md:text-sm lg:text-base 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-medium w-full bg-white text-textColor" onClick={(e)=>handleSubmit(e)}>
                        Sign Up
                      </button>
                      
                    </div>
                  </form>
                  {/* divider */}
                  <div className="flex w-full gap-2 items-center pt-4">
                    <div className="border border-gray-300 w-5/12"></div>
                    <div className="w-2/12 justify-center flex text-xs text-paragraphColor">
                      <span>OR</span>
                    </div>
                    <div className="border w-5/12 border-gray-300"></div>
                  </div>
                  {/* social signup */}
                  <div className="flex flex-col gap-4 pt-4 hidden">
                    <div className="flex justify-center w-full relative">
                      <button className="2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 text-sm 2xl:text-base md:text-sm lg:text-base rounded-lg font-medium w-full bg-white text-textColor">
                        Continue With Google
                      </button>
                      <svg
                        className="absolute left-8 top-1/2 -translate-y-1/2 -translate-x-1/2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M22.5005 12.7326C22.5005 11.8693 22.4291 11.2393 22.2744 10.5859H12.2148V14.4826H18.1196C18.0006 15.4509 17.3577 16.9093 15.9291 17.8892L15.9091 18.0197L19.0897 20.4344L19.3101 20.4559C21.3338 18.6242 22.5005 15.9292 22.5005 12.7326Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M12.214 23.0015C15.1068 23.0015 17.5353 22.0682 19.3092 20.4582L15.9282 17.8914C15.0235 18.5098 13.8092 18.9414 12.214 18.9414C9.38069 18.9414 6.97596 17.1098 6.11874 14.5781L5.99309 14.5886L2.68583 17.0969L2.64258 17.2148C4.40446 20.6448 8.0235 23.0015 12.214 23.0015Z"
                          fill="#34A853"
                        />
                        <path
                          d="M6.12095 14.5755C5.89476 13.9222 5.76386 13.2221 5.76386 12.4988C5.76386 11.7754 5.89476 11.0755 6.10905 10.4221L6.10306 10.283L2.75435 7.73438L2.64478 7.78545C1.91862 9.2088 1.50195 10.8072 1.50195 12.4988C1.50195 14.1905 1.91862 15.7888 2.64478 17.2121L6.12095 14.5755Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.2141 6.05997C14.2259 6.05997 15.583 6.91163 16.3569 7.62335L19.3807 4.73C17.5236 3.03834 15.1069 2 12.2141 2C8.02353 2 4.40447 4.35665 2.64258 7.78662L6.10686 10.4233C6.97598 7.89166 9.38073 6.05997 12.2141 6.05997Z"
                          fill="#EB4335"
                        />
                      </svg>
                    </div>
                    <div className="flex justify-center w-full relative">
                      <button className="2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 text-sm 2xl:text-base md:text-sm lg:text-base rounded-lg font-medium w-full bg-white text-textColor">
                        Continue With Apple
                      </button>
                      <svg
                        className="absolute left-8 top-1/2 -translate-y-1/2 -translate-x-1/2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M22.5 12.5C22.5 18.296 17.8012 23 12 23C6.19875 23 1.5 18.296 1.5 12.5C1.5 6.69875 6.19875 2 12 2C17.8012 2 22.5 6.69875 22.5 12.5Z"
                          fill="#283544"
                        />
                        <path
                          d="M16.9216 9.84304C16.8643 9.87646 15.5003 10.5819 15.5003 12.1459C15.5646 13.9296 17.2216 14.5551 17.25 14.5551C17.2216 14.5885 16.9998 15.4072 16.343 16.2654C15.8217 17.0047 15.2432 17.75 14.3646 17.75C13.5289 17.75 13.2289 17.2573 12.2646 17.2573C11.229 17.2573 10.936 17.75 10.1431 17.75C9.26458 17.75 8.64315 16.9647 8.09345 16.2324C7.37932 15.2739 6.77233 13.7698 6.7509 12.3256C6.73646 11.5603 6.89392 10.808 7.29361 10.169C7.85774 9.27699 8.86489 8.67143 9.96473 8.65146C10.8074 8.62498 11.5574 9.1906 12.0717 9.1906C12.5646 9.1906 13.486 8.65146 14.5286 8.65146C14.9786 8.6519 16.1786 8.77822 16.9216 9.84304ZM12.0005 8.49866C11.8505 7.79978 12.2646 7.10089 12.6503 6.65508C13.1432 6.11594 13.9216 5.75 14.5929 5.75C14.6357 6.44889 14.3641 7.13432 13.8787 7.63352C13.4432 8.17266 12.6932 8.57853 12.0005 8.49866Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="flex flex-col text-sm 2xl:text-base lg:text-base xl:text-base md:text-sm gap-2 text-center pt-8">
                    <h4>Already have an account?</h4>
                    <Link to={'/login'} className="text-sm 2xl:text-base md:text-sm lg:text-base 2xl:py-4 xl:py-4 lg:py-3.5 md:py-3 py-3 rounded-lg font-medium w-full bg-white text-textColor">Log In</Link>
                  </div>

                  <div className="pt-8 flex  justify-center text-center text-sm text-gray-500">
                    <p className="w-10/12">This site is protected by reCAPTCHA and the
                    Google Privacy Policy and Terms of Service apply.</p>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-[60%] md:w-[30%] lg:w-[20%] flex flex-col gap-6 px-4 py-24">
              
              {hideBuyer && (
                 <div className="border p-10 rounded shadow bg-white text-center">
                      <h3 className="text-lg font-semibold">Buyer</h3>
                      <p className="text-sm">Are you a buyer?</p>
                      <Link className="text-blue-600 font-semibold" to={'/sign-up/'}>Sign up</Link> here
                  </div>
                )}
                {!hideAgent && (
                  <div className="border p-10 rounded shadow bg-white text-center">
                      <h3 className="text-lg font-semibold">Agent</h3>
                      <p className="text-sm">Are you an agent?</p>
                      <Link className="text-blue-600 font-semibold" to={'/agent/sign-up/'}>Sign up</Link> here
                  </div>
                   )}
                 {!hideBuilder && (
                  <div className="border p-10 rounded shadow bg-white text-center">
                      <h3 className="text-lg font-semibold">Builder</h3>
                      <p className="text-sm">Are you a builder?</p>
                      <Link className="text-blue-600 font-semibold" to={'/builder/sign-up/'}>Sign up</Link> here
                  </div>
                  )}
              </div>
          </div>
      </div>
    </div>
  )
}