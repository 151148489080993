import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useToast } from "@chakra-ui/react";
import axiosInstance from '../../utils/axio-instance';
import { useNavigate  } from "react-router-dom"; 



const GroupLeadPage = () => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [domain, setDomain] = useState("");
  const [email, setEmail] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast(); // Chakra UI toast hook
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const id = pathSegments[pathSegments.length - 1];
    const name = url.searchParams.get("cn");

    setCompanyId(id);
    setCompanyName(name);
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const validateForm = () => {
    let newErrors = {};

    // Validate email
    if (!email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    if (companyId === "new") {
      if (!companyName.trim()) {
        newErrors.companyName = "Company name is required.";
      }
    
      const domain = email.split("@")[1]; 
      if (!domain) {
        newErrors.domain = "Domain is required.";
      }
    }

    // Validate terms acceptance
    if (!agreeTerms) {
      newErrors.agreeTerms = "You must accept the terms.";
    }

    // If email and terms are valid, validate CAPTCHA
    if (Object.keys(newErrors).length === 0) {
      if (!captchaToken) {
        toast({
          title: "CAPTCHA validation failed.",
          description: "Please complete the CAPTCHA validation.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
        return false;
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const comapnyRequestData = companyId === "new"
          ? {
              company: companyName,
              companyId: "new", 
              email: email,
              domain: email.split("@")[1] || "",
            }
          : {
              company: companyName,
              companyId: companyId,
              email: email
            };
            if(localStorage.hasOwnProperty("userid")){
              comapnyRequestData.userId = localStorage.getItem("userid");
            }
        // API request to submit the lead
        const response = await axiosInstance.post(
          `/users/online/lead`,
          comapnyRequestData
        );
        if (response.status == 200) {
          if(localStorage.hasOwnProperty("userid")){
            toast({
              title: "Request sent successfully!",
              description: "Your Group is created successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right"
            });
            navigate("/groups/");
          }else{
            toast({
              title: "Request sent successfully!",
              description: "Your request was successful! Sign up now to continue.",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right"
            });
            navigate("/sign-up");
          }
        }
      } catch (error) {
        toast({
          title: "Error submitting form.",
          description: "Domain does not exist. Please submit the correct company and domain.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      }
    }
  };


  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-gray-200 p-6 rounded-2xl shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold text-center mb-4">{companyName}</h2>
        {companyId != 'new' && (
        <p className="text-sm text-center mb-6">
          This is exclusively for {companyName} employees, there are already 20+ colleagues
          joined. By submitting this form, you will be joining the group.
        </p>
        )}

        <form className="space-y-4" onSubmit={handleSubmit}>
        {companyId === 'new' && (
          <>
        <div>
            <label htmlFor="company_name" className="block text-sm font-medium text-gray-700">
              Company Name
            </label>
            <input
              type="company_name"
              id="company_name"
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            {errors.company_name && <p className="text-red-500 text-sm">{errors.company_name}</p>}
          </div>
          </>
          )}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="terms"
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
            />
            <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
              Accept Terms of Use, Privacy Policy, and Disclosure
            </label>
          </div>
          {errors.agreeTerms && <p className="text-red-500 text-sm">{errors.agreeTerms}</p>}
          <div className="py-2 px-4 text-center rounded-md">
            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY} onChange={handleCaptchaChange} />
          </div>
          <button
            type="submit"
            className="w-full bg-white text-gray py-2 px-4 rounded-md hover:bg-gray-200 border border-blue-300"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GroupLeadPage;
