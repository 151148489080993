import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBarWithoutSignin from "./ProgressBarWithoutSignin";
import { useTranslation } from 'react-i18next'; 


const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t} = useTranslation();
  

  const { role = "builder", step = 3 } = location.state || {};


  return (
    <div className="bg-gray-100 font-linksans">
        <div className="container mx-auto px-12 py-12 md:px-10 sm:px-10 min-h-screen">
             <ProgressBarWithoutSignin step={step} pagerole={role} />
            <div className="flex flex-col items-center justify-center py-24">
              <div className="bg-white p-8 max-w-lg text-center">
                <FiCheckCircle className="w-16 h-16 text-green-500 mx-auto" />
                <h2 className="text-2xl font-bold text-gray-800 mt-4">Thank You!</h2>
                <p className="text-gray-600 mt-2">
                  {t('agent-builder-successmessage')}
                </p>
                <button
                  onClick={() => navigate("/")}
                  className="mt-6 px-6 py-3 border border-blue-600 text-gray font-small rounded-lg shadow-md transition"
                >
                  Go to Home
                </button>
              </div>
            </div>
         </div>
     </div>
  );
};

export default SuccessPage;
