import React, { useEffect, useState } from "react";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { Button, Input, Tooltip, IconButton, useToast } from "@chakra-ui/react";
import { CopyIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import axiosInstance from '../../utils/axio-instance';
import CommonStatusButton from "../../pages/InvitePage/CommonStatusButton"; 
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useNavigate } from "react-router-dom";


const InviteTable = ({ inviteusers,  groupId }) => {
  const [data, setData] = useState([]);
  const [focusedField, setFocusedField] = useState(null);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const [groupsDataById, setGroupsDataById] = useState([]);
  const kcUid= localStorage.getItem("kc_uid");

  useEffect(() => {
    setData(inviteusers);
  }, [inviteusers]);

  const fetchGroupDataById = async (groupId) => {
    // Fetch group data by ID from an API endpoint
    try {
      const response = await axiosInstance.get(`/usrgroup/${groupId}/users` );
      const responseData = response.data;
      //console.log("responseData", responseData);
      setGroupsDataById((prevData) => ({ ...prevData, [groupId]: { users: responseData || [] } }));
      console.log("Updated groupsDataById:", groupsDataById);
    } catch (error) {
      setError(error.message);
    }
  }


  
  const handleSkip = async () => {
    try {
      const response = await axiosInstance.get(
        `/users/skip-invite-emails?kc_uid=${kcUid}&updateType=GROUPINVITES`
      );

      if (response.status === 200) {
        // Redirect to preferences page
        const updatedFlags = { firstUpdate: 2 };
        localStorage.setItem("user_flags", JSON.stringify(updatedFlags));

        navigate("/buyer/buyer-preferences/");
      } else {
        console.error("Failed to skip invite emails");
      }
    } catch (error) {
      console.error("Error skipping invites:", error);
    }
  };

  const handleCopy = (inviteeToken) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    let inviteUrl = `${baseUrl}sign-up?token=${inviteeToken}`;
    if (inviteeToken && inviteeToken.indexOf("_E") > 0) {
      inviteUrl = `${baseUrl}groups/joingroup?token=${inviteeToken}`;
    }
    navigator.clipboard.writeText(inviteUrl);
    toast({
      title: t('copied-clipboard'),
      description: t('copied-clipboard-message', { inviteUrl }),
      status: 'success',
      duration: 2000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const toast = useToast();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleUnfriend = async (invitee) => {
    console.log("inviteedtaata", invitee);
    const inviteeEmail = invitee.email;
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: t('unfriend-confirmmessage', { inviteeEmail }),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unfriend!',
      cancelButtonText: 'Cancel',
      customClass: {
        container: 'swal2-container',
        popup: 'swal2-popup',
        backdrop: 'swal2-backdrop',
        confirmButton: 'swal2-confirm',
        cancelButton: 'swal2-cancel',
      }
    });

    if (result.isConfirmed) {
      let urlPath = ''
      if (invitee.user_id) {
        urlPath = 'user/' + invitee.user_id;
      } else {
        urlPath = 'user/0?email=' + invitee.email;
      }
      try {
        await axiosInstance.delete(`/usrgroup/${groupId}/${urlPath}`);

        fetchGroupDataById(groupId);
        toast({
          title: t('unfriend-message', { inviteeEmail }),
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        setError(error.message);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleAddNewInvite = () => {
    setData((prev) => [{ email: "", status: "inviting...", isNew: true }, ...prev]);
  };

  const handleCancel = (rowIndex, email) => {
    setData((prevData) => prevData.filter((row, index) => index !== rowIndex || row.email !== email));
  };

 const handleSaveRow = async (index, email) => {
  if (email.trim() === "") {
     
    toast({
        title: "Invalid Email",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      
    return; // Ignore empty emails
  }

  if (validateEmail(email)) {
    setData((prev) =>
      prev.map((item, idx) =>
        idx === index ? { ...item, email, isNew: false } : item
      )
    );

    // Prepare the data for batch invite
    const inviteData = {
      invites: [email], // Add more emails if needed
    };

    try {
      const response = await axiosInstance.post(`/usrgroup/${groupId}/invite_batch`, inviteData);

      if (response.status === 200) {
        setData((prev) =>
          prev.map((item, idx) =>
            idx === index ? { ...item, email, status:"invited", isNew: false } : item
          )
        );
        toast({
          title: "Success",
          description: "Invites sent successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        // Reset input or clear the new invite entry here if necessary
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to send invites",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  } else {
    // Show toast message for invalid email
    toast({
      title: "Invalid Email",
      description: "Please enter a valid email address.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  }
};

  const columns = [
    {
      header: "Email",
      accessorKey: "email",
      cell: ({ row }) =>
        row.original.isNew ? (
          <Input
            size="sm"
            value={row.original.email}
            onChange={(e) =>
              setData((prev) =>
                prev.map((item, idx) =>
                  idx === row.index ? { ...item, email: e.target.value } : item
                )
              )
            }
            placeholder="Enter email"
            autoFocus={focusedField === "email"} 
            onFocus={() => setFocusedField("email")} 
          />
        ) : (
          row.getValue("email")
        ),
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => (
        <span className={row.original.status === "invited" ? "text-red-500" : "text-green-500"}>
          {row.original.status}
        </span>
      ),
    },
    {
      header: "Actions",
      cell: ({ row }) =>
        row.original.isNew ? (
          <div className="flex items-center space-x-2">
          <button size="sm" className="bg-white text-gray border border-blue-300 px-2 py-1" mr='' onClick={() => handleSaveRow(row.index, row.original.email)}>
          Invite
          </button>

          <button size="sm" className="bg-white text-gray border border-blue-300 px-2 py-1" onClick={() => handleCancel(row.index, row.original.email)}>
           Cancel
          </button>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Tooltip label="Copy invite URL">
              <IconButton
                icon={<CopyIcon />}
                size="sm"
                onClick={() => handleCopy(row.original.token)}
                aria-label="Copy invite URL"
              />
            </Tooltip>
            <Tooltip label="Remove invite">
              <IconButton
                icon={<DeleteIcon />}
                size="sm"
                onClick={() => handleUnfriend(row.original)}
                aria-label="Remove invite"
              />
            </Tooltip>
          </div>
        ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
    <div className="border border-gray-200 p-4 rounded-md bg-white min-h-[500px] sm:min-h-[500px] md:min-h-[500px] lg:min-h-[500px] xl:min-h-[500px]">
        {groupId ? (
        <>
        <button leftIcon={<AddIcon />} className="py-2 px-4 bg-white text-gray float-right rounded-md border border-blue-500 mb-2" onClick={handleAddNewInvite}>
            Invite new
        </button>
        
        <table className="w-full border-collapse mb-4">
            <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="bg-gray-100">
                {headerGroup.headers.map((header) => (
                    <th key={header.id} className="border p-2">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {data.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="text-center">
                    {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="border p-2">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                    ))}
                </tr>
                ))
            ) : (
                <tr>
                <td colSpan={3} className="text-center p-4 text-gray-500">
                    No invites found. Please invite members to the group.
                </td>
                </tr>
            )}
            </tbody>
        </table>
        </>
       ) : (
        <div className="text-center text-gray-600 p-4">
            Please select a group or create a new group.
        </div>
        )}
    </div>

    <div className="flex justify-end mb-4">
      <button className="py-2 px-4 bg-white text-gray float-right rounded-md border border-blue-500 mt-2" onClick={handleSkip}>
      Next
          </button>
    </div>
    </>
  );
};

export default InviteTable;
