import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Select, Textarea, Button, useToast, Text, Checkbox, Box } from "@chakra-ui/react";
import axiosInstance from "../../utils/axio-instance";
import { ticketStatus } from "../../constants";

const TicketUpdate = ({ row, onClose, onStatusUpdate }) => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const toast = useToast();
  const [domainCheck, setDomainCheck] = useState(false);



  const handleSave = async () => {
    // Form validation
    const errors = {};
    if (!selectedStatus) errors.selectedStatus = "Status is required.";
    if (!note.trim()) errors.note = "Reason is required.";

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await axiosInstance.put(`/tickets/update-status/${row.id}`, {
        status: selectedStatus,
        domainCheck: domainCheck,
        note: note.trim(),
      });

      if (response.data.status === "success") {
        toast({
          title: "Success",
          description: response.data.message || "Ticket status updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: 'top-right'
        });
        onStatusUpdate();
        onClose(); // Close modal on success
      } else {
        throw new Error("Failed to update ticket.");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to update ticket. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: 'top-right'
      });
    }
  };




  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Ticket Status</ModalHeader>
        <ModalBody>
        <Select
            placeholder="Select Status"
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
              setValidationErrors((prev) => ({ ...prev, selectedStatus: "" }));
            }}
          >
            {ticketStatus.map((status) => (
              <option key={status.value} value={status.value}>
                {status.label}
              </option>
            ))}
          </Select>
          {validationErrors.selectedStatus && (
            <Text color="red.500" fontSize="sm">
              {validationErrors.selectedStatus}
            </Text>
          )}

          {/* Domain Check Checkbox */}
          <Box mt={4} display="flex" alignItems="center">
          &nbsp;
           <Checkbox
              isChecked={domainCheck}
              onChange={(e) => setDomainCheck(e.target.checked)}
              mr={2}
            />
            <Text fontSize="md" fontWeight="normal">
              Domain Check:
            </Text>
          </Box>

          {/* Textarea for Note */}
          <Textarea
            mt={4}
            placeholder="Enter a reason for the update"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
              setValidationErrors((prev) => ({ ...prev, note: "" }));
            }}
          />
          {validationErrors.note && (
            <Text color="red.500" fontSize="sm">
              {validationErrors.note}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleSave} colorScheme="green" mr={2}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TicketUpdate;
