

function ReadyToBuy() {
  return (
    <div className="container mx-auto 2xl:py-10 xl:py-10 lg:py-10 md:py-10 py-8">
        <div className="bg-primary px-12 py-12 flex flex-col justify-center items-center rounded-lg ">
            <h3 className="2xl:text-4xl xl:text-3xl lg:text-2xl text-center md:text-2xl text-2xl font-semibold text-textColor pb-5">Ready to get started?  
            </h3>
            <p className="text-paragraphColor pb-8 text-center w-full md:w-1/2">Answer a few quick questions to find the best match and see how we can help you. 
            </p>
            <div>
            <a href="/builder/sign-up/" className="text-[#060605] bg-white hover:bg-btnbg transition-all duration-300 btnfontpage text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 rounded-lg border border-borderColor text-center block w-full sm:w-auto">
            Get Started & Get Buyers</a>
            </div>

        </div>
    </div>
  )
}

export default ReadyToBuy