import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  HStack,
  Select,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react';
import {states} from '../../data/us_states.js';
import axios from 'axios';
import axiosInstance,{axiosInstanceES} from '../../utils/axio-instance.js';
import { useTranslation } from 'react-i18next'; 
import CommunityUploadModal from '../grid/CommunityUploadModal';



const AddCommunityModal = ({ isOpen, onClose, onDataAdded }) => {

  const [formData, setFormData] = useState({
    communityName: '',
    builderName: '',
    uniqueBuilderDomain: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    address: ''
  });

  const roleInfo = localStorage.getItem('roles');  
  const storedUserInfo = localStorage.getItem('userid');
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handlePhotosClick = (id) => {
    setIsModalOpen(true); 
  };
  

  const [errors, setErrors] = useState({});
  const toast = useToast(); 
  const { t } = useTranslation();
    

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };

      // Auto-fill address field
      updatedData.address = `${updatedData.street}, ${updatedData.city}, ${updatedData.state} ${updatedData.zip}`;

      return updatedData;
    });

    // Clear error when user types
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!formData.communityName) newErrors.communityName = 'Community name is required.';
    if (!formData.builderName) newErrors.builderName = 'Builder name is required.';
    if (!formData.street) newErrors.street = 'Street is required.';
    if (!formData.city) newErrors.city = 'City is required.';
    if (!formData.state) newErrors.state = 'State is required.';
    if (!formData.zip) newErrors.zip = 'ZIP code is required.';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };


  const fetchLatLong = async (address) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`);
      const data = response.data;

      if (data.status === "OK" && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("enterif",  data.results[0].geometry.location);
        return { lat: Number(lat), lng: Number(lng) };
      } else {
        console.warn("No results found for the address:", address);
        return { lat: null, lng: null };  // Return null if no results are found
      }
    } catch (error) {
      console.error('Error fetching latitude and longitude:', error);
      return { lat: null, lng: null };
    }
  };

   const savetransformData = async(row) => {
      let lat = null;
      let lng = null;
  
      const fullAddress = `${row.street|| ''}, ${row.city || ''}, ${row.state || ''}, ${row.zip || ''}`;
      if (fullAddress !== ", , , ") {
        try {
          // Wait for the fetchLatLong function to resolve
          const locationData = await fetchLatLong(fullAddress);
          lat = locationData?.lat;
          lng = locationData?.lng;
        } catch (error) {
          console.error("Error fetching lat/lng:", error);
        }
     
      }
  
      return {
        //location: `${frontendData.latitude},${frontendData.longitude}`,
        location:lat && lng  ? `${lat},${lng}` : null, 
        main: {
          sourceId: row.builderName ,
          propertyStatus: "active",
          recordCreatedDate: new Date().toISOString(),
          createdRole: roleInfo,
          createdByUser: storedUserInfo,
          recordType: 'community',
        },
        data: {
          propertyInfo: {
            address: {
              label: `${row.street},${row.city},${row.state},${row.zip}`,
              street: row.street,
              city: row.city,
              state: row.state,
              zip: row.zip,
              metroName: row.city,
              region: `${row.city ? row.city.toUpperCase() : ""}`,
            },
          },
          lotInfo: {
            lotSquareFeet: row.lotSquareFeet || null,
            legalDescription: row.legalDescription || null
          },
        },
        builder: { 
          builderName: row.builderName || null,
          uniqueBuilderDomain: row.uniqueBuilderDomain || null,
        },
        community: {
          communityName: row.communityName || '',
        }
      };
    };


    const handleSaveSubmit = async () => {
        if (!validateForm()) return;
        
            try {
            const transformedData = await savetransformData(formData);
            console.log("transformedData13121",transformedData);
           
            const elasticsearchApiUrl = `/addcommunity`;
        
            // Save to Elasticsearch
            const elasticsearchResponse = await axiosInstanceES.post(elasticsearchApiUrl, transformedData);

             // Save to DynamoDB
             const payload = {
              role : roleInfo,
               properties: [elasticsearchResponse.data.id] 
            };
      
           const dynamoApiUrl = `/users/${storedUserInfo}/properties`;
            const dynamoResponse = await axiosInstance.post(dynamoApiUrl, payload);
            
            // Check if both requests were successful
            if (dynamoResponse.status === 200 && elasticsearchResponse.status === 200) {
              const newPropertyId = elasticsearchResponse.data.id; 
              setSelectedPropertyId(newPropertyId); 
                setFormData({
                  communityName: '',
                  builderName: '',
                  uniqueBuilderDomain: '',
                  street: '',
                  city: '',
                  state: '',
                  zip: '',
                  address: ''
                });
                onDataAdded();
                handlePhotosClick(selectedPropertyId);
                
                toast({
                  title: t('community-addedmessage'),
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                  position: 'top-right',
                  });
  
            } else {
                throw new Error('One of the requests failed');
            }
            } catch (error) {
            console.error('Error saving new row:', error);
            toast({
                title: 'Error',
                description: t('updatefailed'),
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
            });
            }
        };

    const handleClose = async () => {
      setFormData({
        communityName: '',
        builderName: '',
        uniqueBuilderDomain: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        address: ''
      });
       onClose();
    };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="sticky top-0 bg-white z-10 shadow-md">Add Community</ModalHeader>
        <ModalCloseButton style={{ backgroundColor: "white", zIndex: 50 }}/>
        <ModalBody>
          <VStack spacing={4} align="stretch">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormControl isInvalid={errors.communityName}>
            <FormLabel>
            Community Name <span className="text-red-500">*</span>
            </FormLabel>
              <Input
                name="communityName"
                placeholder="Community Name"
                value={formData.communityName}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.communityName}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.builderName}>
            <FormLabel>
            Builder Name <span className="text-red-500">*</span>
            </FormLabel>
              <Input
                name="builderName"
                placeholder="Builder Name"
                value={formData.builderName}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.builderName}</FormErrorMessage>
            </FormControl>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

            <FormControl >
            <FormLabel>
             Builder Domain
            </FormLabel>
              <Input
                name="uniqueBuilderDomain"
                placeholder="Unique Builder Domain"
                value={formData.uniqueBuilderDomain}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl isInvalid={errors.street}>
            <FormLabel>
            Street Name <span className="text-red-500">*</span>
            </FormLabel>
              <Input
                name="street"
                placeholder="Street"
                value={formData.street}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.street}</FormErrorMessage>
            </FormControl>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              <FormControl isInvalid={errors.city}>
                <FormLabel>
                City <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  name="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.city}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.state}>
                <FormLabel>
                State <span className="text-red-500">*</span>
                </FormLabel>
                <Select name="state" value={formData.state} onChange={handleChange}>
                  <option value="">Select State</option>
                    {states.map((state) => (
                        <option key={state.abbreviation} value={state.abbreviation}>
                        {state.name}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{errors.state}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.zip}>
               <FormLabel>
                Zip Code <span className="text-red-500">*</span>
                </FormLabel>
                <Input
                  name="zip"
                  placeholder="ZIP"
                  value={formData.zip}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.zip}</FormErrorMessage>
              </FormControl>
            </div>
          </VStack>
          {isModalOpen && (
          <CommunityUploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          propertyId={selectedPropertyId} // Pass the selected propertyId
          />
      )} 
        </ModalBody>

        <ModalFooter>
         <button
            className="mr-2 px-4 py-2 text-gray-700 border border-blue-600 rounded-md transition"
            onClick={handleClose}
          >
            Close
          </button>
        <button
            className="px-4 py-2 text-gray-700 border border-blue-600 rounded-md transition"
            onClick={handleSaveSubmit}
          >
            Save & New
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCommunityModal;
